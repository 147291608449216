import React from 'react';
import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';
import './style/ActivityItem.css';
import { useSelectedContext } from './SelectedContext';

const capitalizeAndReplaceUnderscores = (text) => {
    return text.replace(/_/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
};

const ActivityItem = ({ type, desc, date, allTab, name, data, present, relevant }) => {
    
    const typeToBackgroundColor = {
        file_move: '#C996FF',
        folder_move: '#94AEC7',
        scan: '#87CEEB',
        export: '#D3FF96',
        manual_edit: '#FF7F50',
        folder_rename: '#FFD700',
        folder_create: '#FFB6C1',
        delete: '#FF6347',
    };

    const { setDocSearch, setGroupLoading, setChildFolderLoading } = useSelectedContext();

    const parsedDate = new Date(date)
    parsedDate.setHours(parsedDate.getHours() - 6);

    const handleLinkClick = (text) => {
        sessionStorage.setItem('selectedListStyle', 'left') 
        if (type === 'scan') {
            sessionStorage.setItem('searchName', name)
            sessionStorage.setItem('searchDate', parsedDate.toLocaleDateString())
            sessionStorage.setItem('searchLen', data.import_len)
            sessionStorage.setItem('currentScreen', 'Import & Export')
        }
        else if (type === 'folder_create') {
            if (!data.parent_folder_id){
                sessionStorage.setItem('searchName', data.folder_name)
            }
            else {
                sessionStorage.setItem('searchFolderName', data.folder_name)
            }
            sessionStorage.setItem('searchDate', parsedDate.toLocaleDateString())
            sessionStorage.setItem('searchNameRep', name)
            sessionStorage.setItem('selectedView', 'Source Documents')
            if (data.new_parent_folder_id === 0){
                sessionStorage.setItem('currentScreen', 'Folder Source')
                setGroupLoading(true)
            }
            else {
                sessionStorage.setItem('currentScreen', 'File Source')
                setChildFolderLoading(true)
            }
        }
        else if (type === 'folder_rename') {
            if (!data.parent_folder_id){
                sessionStorage.setItem('searchName', data.folder_name)
            }
            else {
                sessionStorage.setItem('searchFolderName', data.folder_name)
            }
            const created_date = new Date(data.date_created)
            created_date.setHours(created_date.getHours() - 6);
            sessionStorage.setItem('searchDate', created_date.toLocaleDateString())
            sessionStorage.setItem('searchNameRep', data.created_by)
            sessionStorage.setItem('selectedView', 'Source Documents')
            if (data.parent_folder_id === 0){
                sessionStorage.setItem('currentScreen', 'Folder Source')
            }
            else {
                sessionStorage.setItem('currentScreen', 'File Source')
            }
        }
        else if (type === 'export') {
            sessionStorage.setItem('searchNameRep', name)
            sessionStorage.setItem('searchDate', parsedDate.toLocaleDateString())
            sessionStorage.setItem('searchFolder', data.folder_name)
            sessionStorage.setItem('currentScreen', 'Import & Export')
        }
        else if (type === 'file_move') {
            const created_date = new Date(data.date_created)
            created_date.setHours(created_date.getHours() - 6);
            sessionStorage.setItem('searchNameRep', data.created_by)
            sessionStorage.setItem('searchName', text)
            sessionStorage.setItem('searchDate', created_date.toLocaleDateString())
            sessionStorage.setItem('selectedView', 'Source Documents')
            sessionStorage.setItem('currentScreen', 'File Source')
        }
        else if (type === 'folder_move') {
            if (!data.new_parent_folder_id){
                sessionStorage.setItem('searchName', data.moved_folder_name)
            }
            else {
                sessionStorage.setItem('searchFolderName', data.moved_folder_name)
            }
            const newDate = new Date(data.date_created)
            newDate.setHours(newDate.getHours() - 6);
            sessionStorage.setItem('searchDate', newDate.toLocaleDateString())
            sessionStorage.setItem('searchNameRep', name)
            sessionStorage.setItem('selectedView', 'Source Documents')
            if (data.new_parent_folder_id === 0){
                sessionStorage.setItem('currentScreen', 'Folder Source')
            }
            else {
                sessionStorage.setItem('currentScreen', 'File Source')
            }
        }
        else if (type === 'manual_edit') {
            setDocSearch(true)
            sessionStorage.setItem('searchDoc', text)
            sessionStorage.setItem('selectedView', 'Attributes')
            sessionStorage.setItem('currentScreen', 'File Database')
        }
    };

    const formattedDesc = () => {
        if (type === "scan") {
            const stringIndex = desc.toLowerCase().indexOf("imported");
            const toIndex = desc.toLowerCase().indexOf("folder");

            const subDesc = stringIndex !== -1 ? ' ' + desc.substring(0, stringIndex + 8) : desc;
            const remainingText = stringIndex !== -1 ? desc.substring(stringIndex + 9, toIndex).trim() : '';
            const remainingString = toIndex !== -1 ? desc.substring(toIndex).trim() : '';
    
            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present ? (
                        <Link className='activity-link' to="/importexport" onClick={() => handleLinkClick()}>
                            {remainingText}
                        </Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                    {remainingString}
                </div>
            );
        }
        else if (type === "folder_create") {
            const stringIndex = desc.toLowerCase().indexOf("folder");
            const subDesc = stringIndex !== -1 ? ' ' + desc.substring(0, stringIndex + 7) : desc;
            const remainingText = stringIndex !== -1 ? desc.substring(stringIndex + 7).trim() : '';

            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present && relevant && data.parent_folder_id && data.parent_folder_id !== '' ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type_id}/${data.parent_folder_name}/${data.parent_folder_id}`} onClick={() => handleLinkClick()}>{remainingText}</Link>
                    ) : present && relevant && !data.parent_folder_id ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type_id}`} onClick={() => handleLinkClick()}>{remainingText}</Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                </div>
            );
        }
        else if (type === "folder_rename") {
            const stringIndex = desc.toLowerCase().indexOf("to");
            const subDesc = stringIndex !== -1 ? ' ' + desc.substring(0, stringIndex + 2) : desc;
            const remainingText = stringIndex !== -1 ? desc.substring(stringIndex + 2).trim() : '';

            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present && relevant && data.parent_folder_id ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type}/${data.parent_folder_name}/${data.parent_folder_id}`} onClick={() => handleLinkClick()}>{remainingText}</Link>
                    ) : present && relevant && !data.parent_folder_id ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type}`} onClick={() => handleLinkClick()}>{remainingText}</Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                </div>
            );
        }
        else if (type === "file_move") {
            const movedIndex = desc.toLowerCase().indexOf("moved");
            const toIndex = desc.toLowerCase().lastIndexOf("into");
        
            const subDesc = movedIndex !== -1 ? ' ' + desc.substring(0, movedIndex + 5) : desc;
            const remainingText = toIndex !== -1 ? desc.substring(movedIndex + 5, toIndex).trim() : '';
            const remainingString = toIndex !== -1 ? desc.substring(toIndex).trim() : '';
        
            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present && relevant ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type}/${data.folder_name}/${data.folder_id}`} onClick={() => handleLinkClick(remainingText)}>{remainingText}</Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                    {remainingString}
                </div>
            );
        }
        else if (type === "folder_move") {
            const movedIndex = desc.toLowerCase().indexOf("folder");
            const toIndex = desc.toLowerCase().lastIndexOf("into");
        
            const subDesc = movedIndex !== -1 ? ' ' + desc.substring(0, movedIndex + 7) : desc;
            const remainingText = toIndex !== -1 ? desc.substring(movedIndex + 7, toIndex).trim() : '';
            const remainingString = toIndex !== -1 ? desc.substring(toIndex).trim() : '';
        
            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present && relevant && data.dest_folder_name ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type}/${data.dest_folder_name}/${data.new_parent_folder_id}`} onClick={() => handleLinkClick(remainingText)}>{remainingText}</Link>
                    ) : present && relevant && !data.dest_folder_name ? (
                        <Link className='activity-link' to={`/sourcedocs/${data.doc_type_name}/${data.doc_type}`} onClick={() => handleLinkClick(remainingText)}>{remainingText}</Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                    {remainingString}
                </div>
            );
        }
        else if (type === "export") {
            const stringIndex = desc.toLowerCase().indexOf("folder");
            const subDesc = stringIndex !== -1 ? ' ' + desc.substring(0, stringIndex + 7) : desc;
            const remainingText = stringIndex !== -1 ? desc.substring(stringIndex + 7).trim() : '';
    
            return (
                <div className='linked-activity'>
                    {name} {subDesc}
                    {present ? (
                        <Link className='activity-link' to="/importexport" onClick={() => handleLinkClick()}>
                            {remainingText}
                        </Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                </div>
            );
        }
        else if (type === "delete") {
            return (
                <div className='linked-activity'>
                    {name} {desc}
                </div>
            );
        }
        else if (type === "manual_edit") {
            const fileIndex = desc.toLowerCase().indexOf("file:");
            const subDesc = fileIndex !== -1 ? ' ' + desc.substring(0, fileIndex + 5) : desc;
            const remainingText = fileIndex !== -1 ? desc.substring(fileIndex + 5).trim() : '';
        
            return (
                <div className='linked-activity'>
                    {name}{subDesc}
                    {present && relevant ? (
                        <Link className='activity-link' to={`/databaseview/${data.doc_type_name}/${data.doc_type}/${data.folder_name}/${data.folder_id}`} onClick={() => handleLinkClick(remainingText)}>{remainingText}</Link>
                    ) : (
                        <p>{remainingText}</p>
                    )}
                </div>
            );
        }
        return desc;
    };

    const formattedType = capitalizeAndReplaceUnderscores(type);
    const backgroundColor = typeToBackgroundColor[type] || 'gray';
    const alignStyle = !allTab ? { alignItems: 'center' } : {};

    return (
        <div className='all-activity'>
            <div className='activity-plus-check' style={alignStyle}>
                <div className={`activity-item${allTab ? '' : '-type'}`}>
                    {allTab ? (
                        <div className='typeText' style={{ backgroundColor: backgroundColor }}>
                            {formattedType}
                        </div>
                    ) : (
                        <i className="pi pi-circle-fill" style={{ color: backgroundColor }}></i>
                    )}
                    <div className='descSect'>
                        {formattedDesc()}
                        <Divider className='activity-vert' layout="vertical" />
                        {parsedDate.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour12: true, hour: 'numeric', minute: 'numeric' })} CST
                    </div>
                </div>
            </div>
            <Divider className='activity-horz' />
        </div>
    );
};

export default ActivityItem;
