import React, { useState } from 'react';
import SupportCard from '../components/SupportCard';
import { Divider } from 'primereact/divider';
import { PanelMenu } from 'primereact/panelmenu';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import './style/SupportPage.css';

const SupportPageNew = () => {
  const [visible, setVisible] = useState(false);
  const [descValue, setDescValue] = useState('');
  const [subValue, setSubValue] = useState('');

  const supportLink = 'https://trc.service-now.com/sp?id=sc_cat_item&sys_id=674e3e8c93e0d6d046ec7bbd0bba1033&sysparm_category=dd6a368093e0d6d046ec7bbd0bba104f';

  const items = [
    {
      label: 'I imported a document but cannot find it. Where should I check?',
      items: [{ label: 'Check your import history using the "Import & Export" link. Unidentified documents will be in the "Unclassified Documents" tab.' }]
    },
    {
      label: 'I tried to import a document from a SharePoint site, but I got an error. What should I do?',
      items: [{ label: 'Ensure you have access to the SharePoint site and try again. If issues persist, open a support ticket.' }]
    },
    {
      label: 'I am unable to see documents for a specific project, what should I do?',
      items: [{ label: 'Check your user profile using the "Profile" link. If the project isn’t listed, have your manager add you to it.' }]
    },
    {
      label: 'One of my document attributes has been changed. Is there a record of recent updates?',
      items: [{ label: 'Yes, click the "Activity" link in the navbar to see recent notifications for records on the selected project.' }]
    }
  ];

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
      <Button label="Submit" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
    </div>
  );

  return (
    <div>
      <header id='header-section'>
        <h1 className='header'>How can we help you?</h1>
      </header>

      <section id='card-section'>
        <SupportCard
          desc1='Found a Bug?'
          desc2='Submit a support ticket to report a software issue or suggest an enhancement.'
          linkText='Open a support ticket'
          setVisible={() => setVisible(true)}
          redirectLink={supportLink}
        />
        <SupportCard
          desc1='New to MDI?'
          desc2='Refer to the documentation for guidance on using the software.'
          linkText='Read the documentation'
          setVisible={() => {}}
          redirectLink={""}
        />
        <Dialog
          header="Submit a Support Ticket"
          draggable
          visible={visible}
          style={{ width: '40vw' }}
          onHide={() => setVisible(false)}
          footer={footerContent}
        >
          <div id='support-ticket'>
            <span className="p-float-label">
              <InputText
                id="subject"
                value={subValue}
                onChange={(e) => setSubValue(e.target.value)}
              />
              <label htmlFor="subject">Subject</label>
            </span>
            <span className="p-float-label">
              <InputTextarea
                id="description"
                value={descValue}
                onChange={(e) => setDescValue(e.target.value)}
                rows={5}
                cols={30}
              />
              <label htmlFor="description">Description</label>
            </span>
            <div className="card">
              <p style={{ marginLeft: '5px', marginTop: '0px' }}>Attach a screenshot</p>
              <FileUpload
                name="demo[]"
                url='/api/upload'
                multiple
                accept="image/*"
                maxFileSize={1000000}
                emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
              />
            </div>
          </div>
        </Dialog>
      </section>

      <Divider />

      <section id='frequent-questions'>
        <h2 className='header'>Frequently Asked Questions</h2>
        <PanelMenu model={items} className="w-full md:w-100% faq" />
      </section>
    </div>
  );
};

export default SupportPageNew;