import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { useSelectedContext } from '../components/SelectedContext';
import './style/Account.css'
import api from '../api';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

const Account = () => {

  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [firstNameUpdate, setFirstName] = useState('');
  const [lastNameUpdate, setLastName] = useState('');
  const [emailUpdate, setEmail] = useState('');
  const [firstNameStart, setFirstNameStart] = useState('');
  const [lastNameStart, setLastNameStart] = useState('');
  const [emailStart, setEmailStart] = useState('');
  const { skelData } = useSelectedContext();
  const [isLoading, setIsLoading] = useState(true);
  const toastBottomLeft = useRef(null);

  const showMessage = (ref, severity, message) => {
    ref.current.show({ severity: severity, summary: message, life: 3000 });
  };
  
  useEffect(() => {
    getAllProjects()
    getCurrentUser()
  }, []);

  const getAllProjects = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await api.get('/project-access/', { headers });
        setAssignedProjects(formatFolders(response.data))
    } catch (error) {
        console.error('Failed to get user assigned projects: ', error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const formatFolders = (data) => {
    return data.map((item) => {
      const parsedDate = new Date(item.last_import);
      const formattedDate = !isNaN(parsedDate.getTime())
        ? `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`
        : 'No imports';
  
      return {
        access_id: item.id,
        client: item.client,
        name: item.name,
        total_folders: item.total_folders,
        last_import: formattedDate,
        representative: item.representative
      };
    });
  };

  const getCurrentUser = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await api.get('/users/current-user-info', { headers });
        setCurrentUser(response.data)
        setFirstName(response.data.first_name)
        setLastName(response.data.last_name)
        setEmail(response.data.email)
        setFirstNameStart(response.data.first_name)
        setLastNameStart(response.data.last_name)
        setEmailStart(response.data.email)
    } catch (error) {
        console.error('Failed to get user assigned projects: ', error);
    }
  }

  const footerContent2 = (
    <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible2(false)} className="p-button-text" />
        <Button label="Submit" icon="pi pi-check" onClick={() => setVisible2(false)} autoFocus />
    </div>
  );

  const formik = useFormik({
    initialValues: {
        first_name: '',
        last_name: '',
        email: ''
    },
    validate: (data) => {
        let errors = {};

        return errors;
    },
    onSubmit: () => {
        handleSubmitUpdate(formik.values);
    }
  });

  const handleSubmitUpdate = async (values) => {
    const formattedValues = formatValues(values);
  
    try {
      const token = sessionStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await api.put(`/users/${currentUser.id}`, formattedValues, { headers });
  
      if (formattedValues.email) {
        sessionStorage.clear();
        window.location.href = '/';
        return;
      }
  
      setCurrentUser(response.data);
  
      const { first_name, last_name, email } = response.data;
      if (first_name) setFirstNameStart(first_name);
      if (last_name) setLastNameStart(last_name);
      if (email) setEmailStart(email);
  
      showMessage(toastBottomLeft, 'success', 'User Info Updated');
    } catch (error) {
      console.error('Failed to update user data: ', error);
      showMessage(toastBottomLeft, 'error', 'Error Updating User Info');
    }
  };

  const formatValues = (formikValues) => {
    const formattedValues = {};
  
    for (const key in formikValues) {
      if (formikValues[key] !== '') {
        formattedValues[key] = formikValues[key];
      }
    }
  
    return formattedValues;
  };

  const skelTemplate = () => {
    return (
        <Skeleton width='5rem'/>
    );
};

  return (
    <div id='account-page-container'>
      <div id='account-column-1'>
        <h1>Personal Info</h1>
        <div className="flex flex-column gap-2">
          <label htmlFor="firstname-dis">First Name</label>
          <InputText disabled id="firstname-dis" value={firstNameStart} />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="lastname-dis">Last Name</label>
          <InputText disabled id="lastname-dis" value={lastNameStart} />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="emailaddress-dis">Email Address</label>
          <InputText disabled id="emailaddress-dis" value={emailStart} />
        </div>
        <div id='user-update-buttons'>
          <Button label="Update Personal Info" onClick={() => setVisible1(true)}/>
          <Dialog header="Update Personal Info" draggable visible={visible1} style={{ width: '50vw' }} onHide={() => setVisible1(false)}>
            <form onSubmit={formik.handleSubmit}>
              <div className='update-sect'>
                <div className="flex flex-column gap-2">
                  <label htmlFor="firstname">First Name</label>
                  <InputText id="first_name" name="first_name" checked={formik.values.first_name} onChange={(e) => {formik.setFieldValue('first_name', e.target.value); setFirstName(e.target.value)}} value={firstNameUpdate} />
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="lastname">Last Name</label>
                  <InputText id="last_name" name="last_name" checked={formik.values.last_name} onChange={(e) => {formik.setFieldValue('last_name', e.target.value); setLastName(e.target.value)}} value={lastNameUpdate} />
                </div>
                <div className="flex flex-column gap-2">
                  <label htmlFor="emailaddress">Email Address</label>
                  <InputText id="email" name="email" checked={formik.values.email} onChange={(e) => {formik.setFieldValue('email', e.target.value); setEmail(e.target.value)}} value={emailUpdate} />
                </div>
                <div className="flex flex-column gap-2">
                  <p style={{color: 'red'}}>Caution: Modifying your email address will result in an automatic logout.</p>
                </div>
                <div className="flex flex-row gap-2 justify-content-end" style={{ marginTop: '0px'}}>
                  <Button label="Cancel" icon="pi pi-times" type="button" onClick={() => setVisible1(false)} className="p-button-text" />
                  <Button label="Submit" icon="pi pi-check" type="submit" onClick={() => setVisible1(false)} autoFocus />
                </div>
              </div>
            </form>
          </Dialog>
          <Button outlined label="Change Password" onClick={() => setVisible2(true)}/>
          <Dialog header="Change Password" draggable visible={visible2} style={{ width: '50vw' }} onHide={() => setVisible2(false)} footer={footerContent2}>
            <div className='update-sect'>
              <div className="flex flex-column gap-2">
                <label htmlFor="username">Old Password</label>
                <Password feedback={false} id="oldpass" />
              </div>
              <div className="flex flex-column gap-2">
                <label htmlFor="username">New Password</label>
                <Password id="newpass" />
              </div>
              <div className="flex flex-column gap-2">
                <label htmlFor="username">Confirm New Password</label>
                <Password id="connewpass" />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
      <Divider layout="vertical" />
      <div id='account-column-2'>
        <h1>Assigned Projects</h1>
        {isLoading ? (
            <DataTable showGridlines value={skelData}>
                <Column header="Total Documents" field="total_documents" body={skelTemplate}/>
                <Column header="Date Imported" field="date_imported" body={skelTemplate}/>
                <Column header="Document Size" field="document_size" body={skelTemplate}/>
                <Column header="Imported By" field="representative" body={skelTemplate}/>
                <Column header="Import Info" field="import skel" body={skelTemplate}/>
            </DataTable>
        ) : (
            <DataTable scrollable className='assigned-projects' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} showGridlines value={assignedProjects} selectionMode='single' tableStyle={{ minWidth: '50rem' }}>
                <Column field="client" header="Client Name"/>
                <Column field="name" header="Project Name"/>
                <Column field="total_folders" header="Total Folders"/>
                <Column field="last_import" header="Last Import"/>
                <Column field="representative" header="Created By"/>
            </DataTable>
        )}
      </div>
      <Toast ref={toastBottomLeft} position="bottom-left" />
    </div>
  )
};

export default Account;
