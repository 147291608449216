import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelectedContext } from "../components/SelectedContext";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { TabView, TabPanel } from "primereact/tabview";
import api from "../api";
import "./style/DataDocScreen.css";
import { Skeleton } from "primereact/skeleton";
import { useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { CSSTransition } from "react-transition-group";
import ImportDocMenu from "../components/ImportDocMenu";
import { InputText } from "primereact/inputtext";

const DocScreen = (isAdmin) => {
  const {
    showComponent,
    setShowComponent,
    toggleItemSelection,
    selectedItems,
    setSelectedItems,
    setIsCtrlPressed,
    setIsShiftPressed,
    selectedProjectRow,
    setSelectedProjectRow,
    deleteFileSwitch,
    skelDataFilFol,
    childFileLoading,
    setChildFileLoading,
    childFolderLoading,
    setChildFolderLoading,
    searchFlip,
    setFCSwitch,
    fcSwitch
  } = useSelectedContext();

  const [representatives, setRepresentatives] = useState([]);
  const [representativesF, setRepresentativesF] = useState([]);

  const [filters, setFilters] = useState({
    file_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    date_created: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    folderName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    date_createdF: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    representativeF: { value: null, matchMode: FilterMatchMode.IN },
  });

  const [selectedTab, setSelectedTab] = useState("");
  const [icon, setIcon] = useState("pi-sort-alpha-down");
  const [folderIcon, setFolderIcon] = useState("pi-sort-alpha-down");
  const [sortOrder, setSortOrder] = useState("None");
  const [sortOrderFolder, setSortOrderFolder] = useState("None");
  const [sortedAllFiles, setSortedAllFiles] = useState([]);
  const [sortedFolders, setSortedFolders] = useState([]);
  const [sortedPinFolders, setSortedPinFolders] = useState([]);
  const [filter, setFilter] = useState(false);
  const [folderFilter, setFolderFilter] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);
  const [renameVisible, setRenameVisible] = useState(false);
  const [folderToRename, setFolder] = useState(0);
  const [folderToRenameName, setFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [parentId, setParentId] = useState(null);
  const [moveLoading, setMoveLoading] = useState(true);
  const [folders, setFolders] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [moveFolderVisible, setMoveFolderVisible] = useState(false);
  const [rootMove, setRootMove] = useState(false);
  const [delVisible, setDelVisible] = useState(false);

  const today = new Date().toLocaleDateString();
  const op = useRef(null);
  const ops = useRef(null);
  const { selectedStationId, selectedDocId, selectedDocType } = useParams();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfUrl, setPdfURL] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [projectFolders, setProjectFolders] = useState([]);
  const [projectFoldersPin, setProjectFoldersPin] = useState([]);

  const toastBottomLeft = useRef(null);

  const showMessage = (ref, severity, message) => {
    ref.current.show({ severity: severity, summary: message, life: 3000 });
  };

  const toggleOrientation = () => {
    const newRotation = (rotation + 90) % 360;
    setRotation(newRotation === 0 ? 0 : newRotation);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.5);
  };

  const handleZoomOut = () => {
    setZoom(Math.max(zoom - 0.5, 0.5));
  };

  useEffect(() => {
    const storedView = sessionStorage.getItem("selectedView");
    const storedSearchFile = sessionStorage.getItem("searchFile");
    const storedSearchName = sessionStorage.getItem("searchName");
    const storedSearchFolderName = sessionStorage.getItem("searchFolderName");
  
    if (storedView !== null) {
      if (storedView === "Attributes") {
        setSelectedTab("databaseview");
      } else if (storedView === "Source Documents") {
        setSelectedTab("sourcedocs");
      }
    }
  
    const initialFilters = {
      file_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date_created: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      representative: { value: null, matchMode: FilterMatchMode.IN },
      folderName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date_createdF: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      representativeF: { value: null, matchMode: FilterMatchMode.IN },
    };
  
    if (storedSearchFile && storedSearchFile !== "") {
      initialFilters.file_name.constraints[0].value = storedSearchFile;
      sessionStorage.removeItem("searchFile");
    } else if (storedSearchName && storedSearchName !== "") {
      initialFilters.file_name.constraints[0].value = storedSearchName;
      initialFilters.date_created.constraints[0].value = new Date(sessionStorage.getItem("searchDate"));
      initialFilters.representative.value = [sessionStorage.getItem("searchNameRep")];
      sessionStorage.removeItem("searchName");
      sessionStorage.removeItem("searchDate");
      sessionStorage.removeItem("searchNameRep");
    } else if (storedSearchFolderName && storedSearchFolderName !== "") {
      initialFilters.folderName.constraints[0].value = storedSearchFolderName;
      initialFilters.date_createdF.constraints[0].value = new Date(sessionStorage.getItem("searchDate"));
      initialFilters.representativeF.value = [sessionStorage.getItem("searchNameRep")];
      sessionStorage.removeItem("searchFolderName");
      sessionStorage.removeItem("searchDate");
      sessionStorage.removeItem("searchNameRep");
    }
    setFilters(initialFilters);

  }, [selectedStationId, searchFlip]);
  

  useEffect(() => {
    if (childFileLoading) {
      getFileData();
    }
  }, [deleteFileSwitch, childFileLoading, selectedStationId]);

  useEffect(() => {
    if (childFolderLoading) {
      getChildFolders();
    }
  }, [childFolderLoading, selectedStationId]);

  const getFileData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const response = await api.get(`/files/get-files/${selectedStationId}`, { headers });
      const formattedData = formatFileResponse(response.data);
      const sortedData = formattedData.sort((a, b) => b.date_created - a.date_created);
      const dataWithSortId = sortedData.map((item, index) => ({
        ...item,
        sort_id: index + 1,
      }));
  
      setFolderFiles(dataWithSortId);
      setRepresentatives(getAllRepresentatives(response.data));
    } catch (error) {
      console.error("Failed to get file data: ", error);
    } finally {
      setChildFileLoading(false);
    }
  };

  const getChildFolders = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const [response, responseAct, responseP] = await Promise.all([
        api.get(`/folders/get-child-folders/${selectedStationId}`, { headers }),
        api.get(`/activity/${parseInt(sessionStorage.getItem("selectedProjectId"), 10)}`, { headers }),
        api.get(`/pinned-folders/get-pinned-child-folders/${selectedStationId}`, { headers }),
      ]);
  
      const formattedProjectFolders = formatFolders(response.data, responseAct.data);
      const sortedProjectFolders = formattedProjectFolders.sort((a, b) => b.date_createdF - a.date_createdF);
      setProjectFolders(sortedProjectFolders);
  
      const formattedPinnedFolders = formatFolders(responseP.data, responseAct.data);
      const sortedPinnedFolders = formattedPinnedFolders.sort((a, b) => b.date_createdF - a.date_createdF);
      setProjectFoldersPin(sortedPinnedFolders);
  
      setRepresentativesF(getAllRepresentativesF(response.data));
    } catch (error) {
      console.error("Failed to get child folders: ", error);
    } finally {
      setChildFolderLoading(false);
      adjustFlexBasis();
    }
  };

  const formatFolders = (data, activity) => {
    return data.map((item) => {
      const parsedDate = new Date(item.date_created);
  
      if (!isNaN(parsedDate.getTime())) {
        parsedDate.setHours(parsedDate.getHours() - 6);
      } else {
        console.error("Invalid date string:", item.date_created);
      }
  
      return {
        folderName: item.folder_name,
        total_documents: item.total_documents,
        date_createdF: parsedDate,
        representativeF: item.created_by,
        folder_id: item.folder_id,
        folder_pinned: item.folder_pinned,
        activityList: activity.filter(
          (activityItem) =>
            activityItem.meta_data.folder_id &&
            activityItem.meta_data.folder_id === item.folder_id
        ).sort((a, b) => b.date_created - a.date_created),
        parent_folder_id: item.parent_folder_id,
        folder_count: item.folder_count,
      };
    });
  };

  const formatMoveFolders = (data, folder) => {
    const filteredData = data
      .filter(({ folder_id }) => folder_id !== folder)
      .map(({ folder_name, folder_id }) => ({
        name: folder_name,
        code: folder_id,
      }));

    return filteredData;
  };

  const getFolderItems = () => {
    const sortedPinFoldersResult = sortOrderFolder === "None" ? [...projectFoldersPin] : [...projectFoldersPin].sort((a, b) => sortOrderFolder === "Asc" ? a.folderName.localeCompare(b.folderName) : b.folderName.localeCompare(a.folderName));
    const sortedAllFoldersResult = sortOrderFolder === "None" ? [...projectFolders] : [...projectFolders].sort((a, b) => sortOrderFolder === "Asc" ? a.folderName.localeCompare(b.folderName) : b.folderName.localeCompare(a.folderName));

    setSortedPinFolders(sortedPinFoldersResult);
    setSortedFolders(sortedAllFoldersResult);
  };

  useEffect(() => {
    if (sortOrderFolder === "Asc") {
      setFolderIcon("pi pi-sort-alpha-down");
    } else if (sortOrderFolder === "Desc") {
      setFolderIcon("pi pi-sort-alpha-up-alt");
    } else if (sortOrderFolder === "None") {
      setFolderIcon("pi pi-sort-alt");
    }
    getFolderItems();
  }, [projectFolders, projectFoldersPin]);

  const getAllRepresentatives = (data) => {
    const uniqueRepresentatives = Array.from(
      new Set(data.map((item) => item.representative))
    );
    return uniqueRepresentatives;
  };

  const getAllRepresentativesF = (data) => {
    const uniqueRepresentatives = Array.from(
      new Set(data.map((item) => item.created_by))
    );
    return uniqueRepresentatives;
  };

  const formatFileResponse = (data) => {
    return data.map((item, index) => {
      const parsedDate = new Date(item.date_created);
      let formattedDate = "Invalid Date";
  
      if (!isNaN(parsedDate.getTime())) {
        parsedDate.setHours(parsedDate.getHours() - 6);
        formattedDate = parsedDate;
      } else {
        console.error("Invalid date string:", item.date_created);
      }
  
      return {
        id: item.id,
        file_name: item.file_name,
        date_created: formattedDate,
        document_size: (item.document_size / Math.pow(1024, 2)).toFixed(2) + " MB",
        representative: item.representative,
        verified: item.verified
      };
    });
  };

  const handleAscClick = (e) => {
    setSortOrder("Asc");
    op.current.toggle(e);
  };

  const handleDescClick = (e) => {
    setSortOrder("Desc");
    op.current.toggle(e);
  };

  const removeSort = (e) => {
    setSortOrder("None");
    op.current.toggle(e);
  };

  const handleFolderAscClick = (e) => {
    setSortOrderFolder("Asc");
    ops.current.toggle(e);
  };

  const handleFolderDescClick = (e) => {
    setSortOrderFolder("Desc");
    ops.current.toggle(e);
  };

  const removeFolderSort = (e) => {
    setSortOrderFolder("None");
    ops.current.toggle(e);
  };

  useEffect(() => {
    let newIcon = "pi pi-sort-alt";
    if (sortOrder === "Asc") {
      newIcon = "pi pi-sort-alpha-down";
    } else if (sortOrder === "Desc") {
      newIcon = "pi pi-sort-alpha-up-alt";
    }
  
    setIcon(newIcon);
  
    const newSortedAllFiles =
      sortOrder === "None"
        ? [...folderFiles]
        : [...folderFiles].sort((a, b) => {
            if (sortOrder === "Asc") {
              return a.file_name.localeCompare(b.file_name);
            } else if (sortOrder === "Desc") {
              return b.file_name.localeCompare(a.file_name);
            }
          }).map((item, index) => ({
            ...item,
            sort_id: index + 1,
          }));
  
    setSortedAllFiles(newSortedAllFiles);
  }, [sortOrder, filter, folderFiles]);
  

  useEffect(() => {
    let newFolderIcon = "pi pi-sort-alt";
    if (sortOrderFolder === "Asc") {
      newFolderIcon = "pi pi-sort-alpha-down";
    } else if (sortOrderFolder === "Desc") {
      newFolderIcon = "pi pi-sort-alpha-up-alt";
    }
    setFolderIcon(newFolderIcon);
  
    const newSortedFolders = sortOrderFolder === "None"
      ? [...projectFolders]
      : [...projectFolders].sort((a, b) => {
          if (sortOrderFolder === "Asc") {
            return a.folderName.localeCompare(b.folderName);
          } else if (sortOrderFolder === "Desc") {
            return b.folderName.localeCompare(a.folderName);
          }
        }).map((item, index) => ({
          ...item,
          sort_id: index + 1,
        }));
  
    const newSortedFoldersPin = sortOrderFolder === "None"
      ? [...projectFoldersPin]
      : [...projectFoldersPin].sort((a, b) => {
          if (sortOrderFolder === "Asc") {
            return a.folderName.localeCompare(b.folderName);
          } else if (sortOrderFolder === "Desc") {
            return b.folderName.localeCompare(a.folderName);
          }
        }).map((item, index) => ({
          ...item,
          sort_id: index + 1,
        }));
  
    setSortedFolders(newSortedFolders);
    setSortedPinFolders(newSortedFoldersPin);
  }, [sortOrderFolder, folderFilter, projectFolders, projectFoldersPin]);  

  const handleSortButtonClick = (e) => {
    op.current.toggle(e);
  };

  const handleFolderSortButtonClick = (e) => {
    ops.current.toggle(e);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const representativeRowFolderFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representativesF}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;
    return (
      <div className="flex align-items-center gap-2">
        <span>{representative}</span>
      </div>
    );
  };

  const representativeBodyTemplateF = (rowData) => {
    const representative = rowData.representativeF;
    return (
      <div className="flex align-items-center gap-2">
        <span>{representative}</span>
      </div>
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey || e.metaKey) {
        setIsCtrlPressed(true);
      }
      if (e.shiftKey) {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (!e.ctrlKey && !e.metaKey) {
        setIsCtrlPressed(false);
      }
      if (!e.shiftKey) {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const previewTemplate = (rowData) => {
    return (
      <div>
        <Button
          label="View Document"
          loading={loading}
          icon="pi pi-file"
          iconPos="left"
          onClick={() => {
            setLoading(true);
            previewFile(rowData.file_name);
          }}
        />
      </div>
    );
  };

  const verificationTemplate = (rowData) => {
    const verificationText = rowData.verified ? "Verified" : "Unverified";
    const textColor = rowData.verified ? "green" : "red";
  
    return (
      <p style={{ color: textColor }}>{verificationText}</p>
    );
  };

  const previewFile = async (name) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await api.get(
        `/files/client/${sessionStorage.getItem("selectedClientId")}/project/${sessionStorage.getItem("selectedProjectId")}/file/${name}`,
        { headers }
      );
      setPdfURL(response.data.pre_signed_url);
      setPreviewVisible(true);
    } catch (error) {
      console.error("Failed to preview file:", error);
    } finally {
      setLoading(false);
    }
  };  

  const skelTemplate = () => {
    return <Skeleton width="5rem" />;
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(12);

  const [firstFolder, setFirstFolder] = useState(0);
  const [rowsFolder, setRowsFolder] = useState(12);

  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const onPageChangeFolder = (e) => {
    setFirstFolder(e.firstFolder);
    setRowsFolder(e.rowsFolder);
  };

  const ScanMenu = ({ rowdata }) => {
    const op = useRef(null);

    const handleButtonClick = (e) => {
      op.current.toggle(e);
    };

    return (
      <div>
        <Button
          rounded
          text
          icon="pi pi-ellipsis-v"
          onClick={handleButtonClick}
        />
        <OverlayPanel ref={op}>
          <div id="doc-opt-menu">
            <Link
              className="doc-opt menu-link"
              to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.folderName}/${rowdata.folder_id}`}
              onClick={() => handleTitleClick(rowdata)}
            >
              Open folder
            </Link>
            <p
              className="doc-opt menu-link"
              onClick={() => {
                setRenameVisible(true);
                setFolder(rowdata.folder_id);
                setFolderName(rowdata.folderName);
              }}
            >
              Rename
            </p>
            <p
              className="doc-opt menu-link"
              onClick={() => {
                moveClick(rowdata);
              }}
            >
              Move folder
            </p>
            {!rowdata.folder_pinned && (
              <p
                className="doc-opt menu-link"
                onClick={() => {
                  handlePinClick(rowdata);
                }}
              >
                Pin folder
              </p>
            )}
            {rowdata.folder_pinned && (
              <p
                className="doc-opt menu-link"
                onClick={() => {
                  handleUnpinClick(rowdata);
                }}
              >
                Unpin Folder
              </p>
            )}
            {(!showComponent ||
              (showComponent && selectedFolder !== rowdata.folderName)) && (
              <Divider />
            )}
            {(selectedFolder !== rowdata.folderName || !showComponent) && (
              <p
                className="doc-opt menu-link"
                onClick={() => {
                  setShowComponent(true);
                  setSelectedFolder(rowdata.folderName);
                  setSelectedProjectRow(rowdata);
                  adjustFlexBasis();
                }}
              >
                Folder info
              </p>
            )}
            <Divider />
            <p className='doc-opt menu-link' onClick={() => downloadFolder(rowdata.folder_id)}>Download</p>
            {isAdmin.isAdmin && (
              <p
                className="doc-opt remove-opt"
                onClick={() => {
                  setDelVisible(true);
                  setFolder(rowdata.folder_id);
                }}
              >
                Delete folder
              </p>
            )}
          </div>
        </OverlayPanel>
      </div>
    );
  };

  const ScanMenuPin = ({ rowdata }) => {
    const op = useRef(null);

    const handleButtonClick = (e) => {
      op.current.toggle(e);
    };

    return (
      <div>
        <Button
          rounded
          text
          icon="pi pi-ellipsis-v"
          onClick={handleButtonClick}
        />
        <OverlayPanel ref={op}>
          <div id="doc-opt-menu">
            <Link
              className="doc-opt menu-link"
              to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.folderName}/${rowdata.folder_id}`}
              onClick={() => handleTitleClick(rowdata)}
            >
              Open Folder
            </Link>
            <p
              className="doc-opt menu-link"
              onClick={() => {
                setRenameVisible(true);
                setFolder(rowdata.folder_id);
                setFolderName(rowdata.folderName);
              }}
            >
              Rename
            </p>
            <p
              className="doc-opt menu-link"
              onClick={() => {
                handleUnpinClick(rowdata);
              }}
            >
              Unpin Folder
            </p>
            {(!showComponent ||
              (showComponent && selectedFolder !== rowdata.folderName)) && (
              <Divider />
            )}
            {(selectedFolder !== rowdata.folderName || !showComponent) && (
              <p
                className="doc-opt menu-link"
                onClick={() => {
                  setShowComponent(true);
                  setSelectedFolder(rowdata.folderName);
                  setSelectedProjectRow(rowdata);
                }}
              >
                Folder info
              </p>
            )}
            <Divider />
            <p className='doc-opt menu-link' onClick={() => downloadFolder(rowdata.folder_id)}>Download</p>
            {isAdmin.isAdmin && (
              <p
                className="doc-opt remove-opt"
                onClick={() => {
                  setDelVisible(true);
                  setFolder(rowdata.folder_id);
                }}
              >
                Delete folder
              </p>
            )}
          </div>
        </OverlayPanel>
      </div>
    );
  };

  const folderTitle = (rowdata) => {
    return (
      <Link
        className="folder-title"
        to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.folderName}/${rowdata.folder_id}`}
        onClick={() => handleTitleClick(rowdata)}
      >
        <p className="menu-link">{rowdata.folderName}</p>
        {rowdata.folder_pinned && (
          <i className="pi pi-bookmark" style={{ fontSize: "1rem" }}></i>
        )}
      </Link>
    );
  };

  const handleTitleClick = (data) => {
    sessionStorage.removeItem("searchDoc");
    sessionStorage.setItem("currentScreen", "File Source");
    sessionStorage.setItem(
      "folderPath",
      sessionStorage.getItem("folderPath") + "," + data.folderName
    );
    sessionStorage.setItem(
      "folderIdPath",
      sessionStorage.getItem("folderIdPath") + "," + data.folder_id
    );
    setChildFolderLoading(true);
    setChildFileLoading(true);
    resetFilters();
  };

  const moveClick = (data) => {
    setFolder(data.folder_id);
    setFolderName(data.folderName);
    setParentId(data.parent_folder_id);
    setMoveLoading(true);
    getMoveFolders(data.folder_id);
    setMoveFolderVisible(true);
  };

  const handlePinClick = (data) => {
    pinFolder(data);
  };

  const handleUnpinClick = (data) => {
    unPinFolder(data);
  };

  const unPinFolder = async (data) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      await api.delete(`/pinned-folders/remove-folder-pin/${data.folder_id}`, {
        headers,
      });
  
      setChildFolderLoading(true);
      showMessage(toastBottomLeft, "success", "Unpinned Folder");
    } catch (error) {
      console.error("Failed to unpin folder:", error);
      showMessage(toastBottomLeft, "error", "Error");
    }
  };

  const pinFolder = async (data) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      await api.post(`/pinned-folders/add-folder-pin/${data.folder_id}`, null, {
        headers,
      });
  
      setChildFolderLoading(true);
      showMessage(toastBottomLeft, "success", "Folder Pinned");
    } catch (error) {
      console.error("Failed to pin folder:", error);
      showMessage(toastBottomLeft, "error", "Error");
    }
  };
  

  const getMoveFolders = async (folder) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const projectId = parseInt(sessionStorage.getItem("selectedProjectId"), 10);
      const documentTypeId = parseInt(selectedDocId, 10);
  
      const response = await api.get(
        `/folders/get-move-folders?project_id=${projectId}&document_type_id=${documentTypeId}`,
        { headers }
      );
  
      setFolders(formatMoveFolders(response.data, folder));
      setMoveLoading(false);
    } catch (error) {
      console.error("Failed to get project folders:", error);
    }
  };
  

  const resetFilters = () => {
    setFilters({
      file_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date_created: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      representative: { value: null, matchMode: FilterMatchMode.IN },
      folderName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date_createdF: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      representativeF: { value: null, matchMode: FilterMatchMode.IN },
    });
  };

  const closeAllPanels = (index) => {
    if (activeIndex !== index.index) {
      setActiveIndex(index.index);
      setShowComponent(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      newName: "",
    },
    validate: (data) => {
      let errors = {};

      if (data.newName === "") {
        errors.newName = "Please enter a new name";
      }

      return errors;
    },
    onSubmit: () => {
      handleSubmitRename(formik.values);
      formik.resetForm();
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleSubmitRename = async (values) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      const requestBodyAll = { folder_name: values.newName };
      const response = await api.put(
        `/folders/rename-folder/${folderToRename}`,
        requestBodyAll,
        { headers }
      );
  
      const folderResponse = await api.get(
        `/folders/get-folder-info/${response.data.parent_folder_id}`,
        { headers }
      );
  
      const user = await api.get(`/users/user-info/${response.data.user_id}`, {
        headers,
      });
  
      const requestBodyAct = {
        type_id: 5,
        project_id: parseInt(sessionStorage.getItem("selectedProjectId"), 10),
        description: `renamed folder "${folderToRenameName}" to ${values.newName}`,
        meta_data: {
          folder_name: response.data.folder_name,
          folder_id: response.data.id,
          date_created: response.data.created_at,
          created_by: `${user.data.first_name} ${user.data.last_name}`,
          parent_folder_id: response.data.parent_folder_id,
          parent_folder_name: folderResponse.data.folder_name,
          doc_type: response.data.document_type_id,
          doc_type_name: selectedDocType,
        },
      };
  
      await api.post(`/activity/add-activity`, requestBodyAct, { headers });
  
      setRenameVisible(false);
      setShowComponent(false);
      showMessage(toastBottomLeft, "success", "Renamed Folder");
      setChildFolderLoading(true);
      setFCSwitch(!fcSwitch);
    } catch (error) {
      console.error("Failed to update folder name:", error);
      showMessage(toastBottomLeft, "error", "Error");
    }
  };
  

  const formik2 = useFormik({
    initialValues: {
      newFolderMoveName: "",
      newFolderMoveId: "",
      checkboxMoveRoot: false,
    },
    validate: (data) => {
      let errors = {};

      if (data.newFolderMoveName === "" && !data.checkboxMoveRoot) {
        errors.newFolderMoveName = "Please select a folder!";
      }

      return errors;
    },
    onSubmit: () => {
      handleSubmitMove(formik2.values);
      formik2.resetForm();
    },
  });

  const handleSubmitMove = async (values) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let target_id = 0;
      if (!values.checkboxMoveRoot) {
        target_id = values.newFolderMoveId;
      }
      const apiUrl = `/folders/move-folder/${folderToRename}/${target_id}`;
      const response = await api.put(apiUrl, null, { headers });
      const user = await api.get(
        `/users/user-info/${parseInt(sessionStorage.getItem("userID"), 10)}`,
        { headers }
      );
      let dest_folder = null;
      let description = "";
      if (target_id === 0) {
        description = `moved folder ${folderToRenameName} into the root`;
      } else {
        dest_folder = String(values.newFolderMoveName).trim();
        description = `moved folder ${folderToRenameName} into folder: ${values.newFolderMoveName}`;
      }
      const requestBodyAct = {
        type_id: 8,
        project_id: parseInt(sessionStorage.getItem("selectedProjectId"), 10),
        description: description,
        meta_data: {
          dest_folder_name: dest_folder,
          moved_folder_id: response.data.id,
          moved_folder_name: String(folderToRenameName).trim(),
          date_created: response.data.created_at,
          created_by: `${user.data.first_name} ${user.data.last_name}`,
          new_parent_folder_id: target_id,
          doc_type: response.data.document_type_id,
          doc_type_name: selectedDocType,
        },
      };
      const apiUrlAct = `/activity/add-activity`;
      await api.post(apiUrlAct, requestBodyAct, { headers });
      setMoveFolderVisible(false);
      setChildFolderLoading(true);
      setRootMove(false);
      showMessage(toastBottomLeft, "success", `Folder move successful!`);
      setSelectedFolder(null);
      formik2.resetForm();
    } catch (error) {
      console.error("Failed to move folder: ", error);
      showMessage(toastBottomLeft, "error", "Error");
    }
  };

  const downloadFolder = async (id) => {
    try {
        const token = sessionStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const requestBody = {
            folder_id: id
        };
        const response = await api.post(`/files/download_files`, requestBody, { headers, responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_files.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch (error) {
        console.error('Failed to download folder: ', error);
    }
  };

  const moveHeaderElement = (
    <div className="move-header">
      <p>Move to folder</p>
      {moveLoading && (
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.25rem" }}></i>
      )}
    </div>
  );

  const adjustFlexBasis = () => {
    const elements = document.getElementsByClassName('child-station-folder');
    let maxWidth = 0;
    for (let i = 0; i < elements.length; i++) {
      const width = elements[i].offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    }
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.flexBasis = `${maxWidth}px`;
    }
  };

  const isFormFieldInvalid2 = (name) =>
    !!(formik2.touched[name] && formik2.errors[name]);

  const getFormErrorMessage2 = (name) => {
    return isFormFieldInvalid2(name) ? (
      <small className="p-error">{formik2.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const delFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDelVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          setDelVisible(false);
          handleSubmitDelete();
        }}
        autoFocus
      />
    </div>
  );

  const handleSubmitDelete = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      const folderResponse = await api.get(
        `/folders/get-folder-items/${folderToRename}`,
        { headers }
      );
  
      if (folderResponse.data[0].files.length > 0) {
        const requestBody = {
          file_ids: folderResponse.data[0].files.map((item) => item.id),
        };
        await api.post(`/files/delete-files`, requestBody, { headers });
  
        await Promise.all(
          folderResponse.data[0].files.map(async (item) => {
            const itemName = item.name.includes("/")
              ? item.name.substring(item.name.lastIndexOf("/") + 1)
              : item.name;
            const requestBodyAct = {
              type_id: 4,
              project_id: parseInt(
                sessionStorage.getItem("selectedProjectId"),
                10
              ),
              description: `deleted file: ${itemName}`,
              meta_data: {
                file_id: item.id,
                full_file_name: item.name,
                project: sessionStorage.getItem("selectedProject"),
                client: sessionStorage.getItem("selectedClient"),
              },
            };
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
          })
        );
      }
  
      await Promise.all(
        folderResponse.data[0].folders.map(async (item) => {
          await api.delete(`/folders/delete-folder/${item.id}`, { headers });
          const requestBodyAct = {
            type_id: 4,
            project_id: parseInt(sessionStorage.getItem("selectedProjectId"), 10),
            description: `deleted folder: ${item.name}`,
            meta_data: {
              folder_id: item.id,
            },
          };
          const apiUrlAct = `/activity/add-activity`;
          await api.post(apiUrlAct, requestBodyAct, { headers });
        })
      );
  
      await api.delete(
        `batches/clean-up-batches/${parseInt(
          sessionStorage.getItem("selectedProjectId"),
          10
        )}`,
        { headers }
      );
  
      showMessage(toastBottomLeft, "success", "Folder Deleted");
      setChildFolderLoading(true);
      setFCSwitch(!fcSwitch);
    } catch (error) {
      console.error("Failed to delete folder:", error);
      showMessage(toastBottomLeft, "error", "Error");
    }
  };
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div
      id={
        sessionStorage.getItem("selectedListStyle") === "left"
          ? "data-tab"
          : "data-tab-icons"
      }
      style={{ display: sessionStorage.getItem("selectedListStyle") === "right" ? 'flex' : '' }}
      className="doc-data-tab"
    >
      {sessionStorage.getItem("selectedListStyle") === "left" && (
        <div id="child-table" style={{ flexGrow: 1, width: showComponent ? '67%' : 'auto'}}>
          {childFileLoading ? (
            <div>
              <h2 className="table-titles">Files:</h2>
              <DataTable showGridlines value={skelDataFilFol}>
                <Column selectionMode="multiple" disabled={childFileLoading} body={skelTemplate} />
                <Column header="Name" body={skelTemplate} />
                <Column header="Date Created" body={skelTemplate} />
                <Column header="Document Size" body={skelTemplate} />
                <Column header="Created By" body={skelTemplate} />
              </DataTable>
            </div>
          ) : (
            <div>
              <h2 className="table-titles">Files:</h2>
              <DataTable scrollable className="file-table" showGridlines paginator rows={3} rowsPerPageOptions={[3, 6, 9, 12]} value={folderFiles} selectionMode={null} emptyMessage="No items found." filters={filters} selection={selectedItems} onSelectionChange={(e) => { setSelectedItems(e.value); }} removableSort tableStyle={{ minWidth: "" }}>
                <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
                <Column header="Name" filterField="file_name" filter filterPlaceholder="Search by name" sortable field="file_name" />
                <Column header="Date Created" dataType="date" filterField="date_created" field="date_created" sortable filter filterElement={dateFilterTemplate} body={(rowData) => (<span>{rowData.date_created.toLocaleDateString("en-US")}</span>)} />
                <Column header="Document Size" field="document_size" sortable />
                <Column sortable filter header="Created By" field="representative" filterField="representative" showFilterMatchModes={false} style={{ minWidth: "14rem" }} body={representativeBodyTemplate} filterElement={representativeRowFilterTemplate} />
                <Column header="File Verification" field="verified" body={verificationTemplate}/>
                <Column header="Preview" body={previewTemplate} />
              </DataTable>
            </div>
          )}
          {childFolderLoading ? (
            <div>
              <h2 className="table-titles">Folders:</h2>
              <DataTable showGridlines value={skelDataFilFol}>
                <Column selectionMode="multiple" disabled={childFileLoading} body={skelTemplate} />
                <Column header="Name" body={skelTemplate} />
                <Column header="Total Documents" body={skelTemplate} />
                <Column header="Total Folders" body={skelTemplate} />
                <Column header="Date Created" body={skelTemplate} />
                <Column header="Created By" body={skelTemplate} />
                <Column header="Folder Options" body={skelTemplate} />
              </DataTable>
            </div>
          ) : (
            <div>
              <h2 className="table-titles">Folders:</h2>
              <TabView activeIndex={activeIndex} style={{ flex: 1 }} onTabChange={(activeIndex) => { closeAllPanels(activeIndex); resetFilters(); }}>
                <TabPanel header="All">
                  {sessionStorage.getItem("selectedListStyle") === "left" && (
                    <DataTable scrollable className="file-table" paginator rows={3} rowsPerPageOptions={[3, 6, 9, 12]} showGridlines value={projectFolders} selectionMode="single" emptyMessage="No items found." filters={filters} selection={selectedProjectRow} removableSort tableStyle={{ minWidth: "" }}>
                      <Column header="Name" filter filterPlaceholder="Search by name" sortable field="folderName" body={folderTitle} />
                      <Column header="Total Documents" sortable field="total_documents" />
                      <Column header="Total Folders" sortable field="folder_count" />
                      <Column header="Date Created" dataType="date" filterField="date_createdF" field="date_createdF" sortable filter filterElement={dateFilterTemplate} body={(rowData) => { return (<span>{rowData.date_createdF.toLocaleDateString("en-US")}</span>); }} />
                      <Column sortable filter header="Created By" field="representativeF" filterField="representativeF" showFilterMatchModes={false} style={{ minWidth: "14rem" }} body={representativeBodyTemplateF} filterElement={representativeRowFolderFilterTemplate} />
                      <Column header="Folder Options" field="name" body={(rowdata, index) => (<ScanMenu rowdata={rowdata} />)} />
                    </DataTable>
                  )}
                </TabPanel>
                <TabPanel header="Pinned">
                  {sessionStorage.getItem("selectedListStyle") === "left" && (
                    <DataTable scrollable className="file-table" showGridlines paginator rows={3} rowsPerPageOptions={[3, 6, 9, 12]} value={projectFoldersPin} filters={filters} selectionMode="single" selection={selectedProjectRow} removableSort tableStyle={{ minWidth: "" }} emptyMessage="No items found.">
                      <Column header="Name" filter filterPlaceholder="Search by name" sortable field="folderName" body={folderTitle} />
                      <Column header="Total Documents" sortable field="total_documents" />
                      <Column header="Total Folders" sortable field="folder_count" />
                      <Column header="Date Created" dataType="date" filterField="date_createdF" field="date_createdF" sortable filter filterElement={dateFilterTemplate} body={(rowData) => (<span>{rowData.date_createdF.toLocaleDateString("en-US")}</span>)} />
                      <Column sortable filter header="Created By" field="representativeF" filterField="representativeF" showFilterMatchModes={false} style={{ minWidth: "14rem" }} body={representativeBodyTemplateF} filterElement={representativeRowFolderFilterTemplate} />
                      <Column header="Folder Options" field="name" body={(rowData, index) => (<ScanMenuPin rowdata={rowData} />)} />
                    </DataTable>
                  )}
                </TabPanel>
              </TabView>
            </div>
          )}
        </div>
      )}
      {sessionStorage.getItem("selectedListStyle") === "right" && (
        <div style={{ flexGrow: 1, width: showComponent ? '67%' : 'auto' }}>
          {sessionStorage.getItem("selectedListStyle") === "right" &&
            (childFileLoading ? (
              <div className="button-plus-loading">
                <h2 className="table-title-icon">Files:</h2>
                <Button disabled label="Sort and Filter" iconPos="right" outlined icon={icon} style={{ marginTop: "5px", marginBottom: "5px" }}>
                  {filter && (<i className="pi pi-filter-fill" style={{ marginLeft: "10px" }}></i>)}
                  {!filter && (<i className="pi pi-filter" style={{ marginLeft: "10px" }}></i>)}
                </Button>
                <img alt="loading gif" src={require("../images/Infinity-1s-200px.gif")} className="p-mr-2" width="100px" />
              </div>
            ) : (
              <div>
                <h2 className="table-title-icon">Files:</h2>
                <Button label="Sort and Filter" icon={icon} iconPos="right" outlined style={{ marginTop: "5px", marginBottom: "5px" }} onClick={handleSortButtonClick}>
                  {filter && (<i className="pi pi-filter-fill" style={{ marginLeft: "10px" }}></i>)}
                  {!filter && (<i className="pi pi-filter" style={{ marginLeft: "10px" }}></i>)}
                </Button>
                <OverlayPanel ref={op}>
                  <div id="doc-opt-menu">
                    <p style={{ fontWeight: "bold", margin: "8px 0px" }}>Filter by:</p>
                    <p className="doc-opt menu-link-sort" onClick={(e) => setFilter(true)}>Recently added</p>
                    <p className="doc-opt remove-opt-sort" onClick={(e) => setFilter(false)}>Remove filter</p>
                    <Divider />
                    <p className="doc-opt menu-link-sort" onClick={handleAscClick}>Sort A-Z</p>
                    <p className="doc-opt menu-link-sort" onClick={handleDescClick}>Sort Z-A</p>
                    <p className="doc-opt remove-opt-sort" onClick={removeSort}>Remove Sort</p>
                  </div>
                </OverlayPanel>
                <div>
                  <div className="doc-item-list">
                    <div id="icon-grid">
                    {filter && sortedAllFiles
                      .filter((item) => item.date_created.toLocaleDateString("en-US") === today)
                      .slice(first, first + rows)
                      .map((item, id) => {
                        const maxLength = 20;
                        const fileName = item.file_name.length > maxLength 
                          ? item.file_name.substring(0, maxLength) + "..."
                          : item.file_name;

                        return (
                          <div key={id} className={`doc-item ${selectedItems.some((selectedItem) => selectedItem.id === item.id) ? "selected" : ""}`} onClick={() => toggleItemSelection(item, sortedAllFiles)}>
                            <i className="pi pi-file-pdf" style={{ fontSize: "4rem", color: "red" }}></i>
                            <p style={{ margin: "0", fontSize: "16px", fontWeight: "normal" }}>{fileName}</p>
                            <p style={{ margin: "0", fontSize: "14px", fontWeight: "lighter" }}>{item.date_created.toLocaleDateString("en-US")}</p>
                            {selectedItems.some((selectedItem) => selectedItem.id === item.id) && (
                              <i style={{ padding: "3px" }} className="pi pi-check-circle check-icon"></i>
                            )}
                          </div>
                        );
                      })}
                      {!filter && sortedAllFiles
                        .slice(first, first + rows)
                        .map((item, id) => {
                          const maxLength = 20;
                          const fileName = item.file_name.length > maxLength 
                            ? item.file_name.substring(0, maxLength) + "..."
                            : item.file_name;

                          return (
                            <div key={id} className={`doc-item ${selectedItems.some((selectedItem) => selectedItem.id === item.id) ? "selected" : ""}`} onClick={() => toggleItemSelection(item, sortedAllFiles)}>
                              <i className="pi pi-file-pdf" style={{ fontSize: "4rem", color: "red" }}></i>
                              <p style={{ margin: "0", fontSize: "16px", fontWeight: "normal" }}>{fileName}</p>
                              <p style={{ margin: "0", fontSize: "14px", fontWeight: "lighter" }}>{item.date_created.toLocaleDateString("en-US")}</p>
                              {selectedItems.some((selectedItem) => selectedItem.id === item.id) && (
                                <i style={{ padding: "3px" }} className="pi pi-check-circle check-icon"></i>
                              )}
                            </div>
                          );
                      })}
                    </div>
                    <Paginator first={first} rows={rows} totalRecords={sortedAllFiles.length} rowsPerPageOptions={[12, 18, 24, 30]} onPageChange={onPageChange} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            ))}
          {sessionStorage.getItem("selectedListStyle") === "right" &&
            (childFolderLoading ? (
              <div className="button-plus-loading">
                <h2 className="table-title-icon">Folders:</h2>
                <TabView activeIndex={activeIndex} style={{ flex: 1 }} onTabChange={(activeIndex) => { closeAllPanels(activeIndex); resetFilters(); }}>
                  <TabPanel header="All">
                    <div id="folder-loading-section">
                      <Button disabled label="Sort and Filter" iconPos="right" outlined icon={folderIcon} style={{ marginTop: "5px", marginBottom: "5px" }}>
                        {folderFilter && <i className="pi pi-filter-fill" style={{ marginLeft: "10px" }}></i>}
                        {!folderFilter && <i className="pi pi-filter" style={{ marginLeft: "10px" }}></i>}
                      </Button>
                      <img alt="loading gif" src={require("../images/Infinity-1s-200px.gif")} className="p-mr-2" width="100px" />
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            ) : (
              <div id="button-plus-icon">
                <h2 className="table-title-icon">Folders:</h2>
                <div id="folder-tabview-container">
                  <TabView activeIndex={activeIndex} style={{ flex: 1 }} onTabChange={(activeIndex) => { closeAllPanels(activeIndex); resetFilters(); }}>
                    <TabPanel header="All">
                      <Button label="Sort and Filter" icon={folderIcon} iconPos="right" outlined style={{ marginTop: "5px", marginBottom: "5px" }} onClick={handleFolderSortButtonClick}>
                        {folderFilter && (<i className="pi pi-filter-fill" style={{ marginLeft: "10px" }}></i>)}
                        {!folderFilter && (<i className="pi pi-filter" style={{ marginLeft: "10px" }}></i>)}
                      </Button>
                      <OverlayPanel ref={ops}>
                        <div id="doc-opt-menu">
                          <p style={{ fontWeight: "bold", margin: "8px 0px" }}>Filter by:</p>
                          <p className="doc-opt menu-link-sort" onClick={() => setFolderFilter(true)}>Recently added</p>
                          <p className="doc-opt remove-opt-sort" onClick={() => setFolderFilter(false)}>Remove filter</p>
                          <Divider />
                          <p className="doc-opt menu-link-sort" onClick={handleFolderAscClick}>Sort A-Z</p>
                          <p className="doc-opt menu-link-sort" onClick={handleFolderDescClick}>Sort Z-A</p>
                          <p className="doc-opt remove-opt-sort" onClick={removeFolderSort}>Remove Sort</p>
                        </div>
                      </OverlayPanel>
                      <div className="folder-list-proj-doc">
                        {!folderFilter && sortedFolders.slice(firstFolder, firstFolder + rowsFolder).map((item, index) => (
                          <div key={index} className="child-station-folder">
                            <div className="child-folder-and-icon">
                              <i className="pi pi-folder"></i>
                              <Link className="folder-title" to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.folderName}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                <p className="menu-link">{item.folderName}</p>
                                {item.folder_pinned && (<i className="pi pi-bookmark" style={{ fontSize: "1rem" }}></i>)}
                              </Link>
                            </div>
                            <ScanMenu rowdata={item} />
                          </div>
                        ))}
                        {folderFilter && sortedFolders.filter((item) => item.date_createdF.toLocaleDateString() === today).slice(firstFolder, firstFolder + rowsFolder).map((item, index) => (
                          <div key={index} className="child-station-folder">
                            <div className="child-folder-and-icon">
                              <i className="pi pi-folder"></i>
                              <Link className="folder-title" to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.folderName}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                <p className="menu-link">{item.folderName}</p>
                                {item.folder_pinned && (<i className="pi pi-bookmark" style={{ fontSize: "1rem" }}></i>)}
                              </Link>
                            </div>
                            <ScanMenu rowdata={item} />
                          </div>
                        ))}
                      </div>
                      <div className="folder-paginator">
                        <Paginator first={firstFolder} rows={rowsFolder} totalRecords={sortedFolders.length} rowsPerPageOptions={[12, 18, 24, 30]} onPageChange={onPageChangeFolder} />
                      </div>
                    </TabPanel>
                    <TabPanel header="Pinned">
                      <Button label="Sort and Filter" icon={folderIcon} iconPos="right" outlined style={{ marginTop: "5px", marginBottom: "5px" }} onClick={handleFolderSortButtonClick}>
                        {folderFilter && (<i className="pi pi-filter-fill" style={{ marginLeft: "10px" }}></i>)}
                        {!folderFilter && (<i className="pi pi-filter" style={{ marginLeft: "10px" }}></i>)}
                      </Button>
                      <OverlayPanel ref={ops}>
                        <div id="doc-opt-menu">
                          <p style={{ fontWeight: "bold", margin: "8px 0px" }}>Filter by:</p>
                          <p className="doc-opt menu-link-sort" onClick={(e) => setFolderFilter(true)}>Recently added</p>
                          <p className="doc-opt remove-opt-sort" onClick={(e) => setFolderFilter(false)}>Remove filter</p>
                          <Divider />
                          <p className="doc-opt menu-link-sort" onClick={handleFolderAscClick}>Sort A-Z</p>
                          <p className="doc-opt menu-link-sort" onClick={handleFolderDescClick}>Sort Z-A</p>
                          <p className="doc-opt remove-opt-sort" onClick={removeFolderSort}>Remove Sort</p>
                        </div>
                      </OverlayPanel>
                      <div className="folder-list-proj-doc">
                        {!folderFilter && sortedPinFolders.slice(firstFolder, firstFolder + rowsFolder).map((item, index) => (
                          <div key={index} className="child-station-folder">
                            <div className="child-folder-and-icon">
                              <i className="pi pi-folder"></i>
                              <Link className="folder-title" to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.folderName}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                <p className="menu-link">{item.folderName}</p>
                                {item.folder_pinned && (<i className="pi pi-bookmark" style={{ fontSize: "1rem" }}></i>)}
                              </Link>
                            </div>
                            <ScanMenuPin rowdata={item} />
                          </div>
                        ))}
                        {folderFilter && sortedPinFolders.filter((item) => item.date_createdF.toLocaleDateString() === today).slice(firstFolder, firstFolder + rowsFolder).map((item, index) => (
                          <div key={index} className="child-station-folder">
                            <div className="child-folder-and-icon">
                              <i className="pi pi-folder"></i>
                              <Link className="folder-title" to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.folderName}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                <p className="menu-link">{item.folderName}</p>
                                {item.folder_pinned && (<i className="pi pi-bookmark" style={{ fontSize: "1rem" }}></i>)}
                              </Link>
                            </div>
                            <ScanMenuPin rowdata={item} />
                          </div>
                        ))}
                      </div>
                      <div className="folder-paginator">
                        <Paginator first={firstFolder} rows={rowsFolder} totalRecords={sortedPinFolders.length} rowsPerPageOptions={[12, 18, 24, 30]} onPageChange={onPageChangeFolder} />
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            ))}
        </div>
      )}
      <CSSTransition in={showComponent} timeout={300} classNames="slide1" unmountOnExit style={{ flexGrow: '1' }}>
        <div>
          <ImportDocMenu docActivity={true} selectedFolder={selectedFolder} data={selectedProjectRow} />
        </div>
      </CSSTransition>
      <Dialog header="PDF Preview" maximizable draggable visible={previewVisible} onHide={() => setPreviewVisible(false)}>
        <div className="pdf-buttons">
          <Button label="Rotate" icon="pi pi-refresh" iconPos="right" onClick={toggleOrientation} />
          <Button label="Zoom In" icon="pi pi-plus" iconPos="right" onClick={handleZoomIn} />
          <Button label="Zoom Out" icon="pi pi-minus" iconPos="right" onClick={handleZoomOut} />
          <Button label="Previous Page" disabled={pageNumber <= 1} icon="pi pi-chevron-left" iconPos="right" onClick={previousPage} />
          <Button label="Next Page" disabled={pageNumber >= numPages} icon="pi pi-chevron-right" iconPos="right" onClick={nextPage} />
        </div>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} rotate={rotation} scale={zoom} />
        </Document>
      </Dialog>
      <Dialog header="Enter New Folder Name" draggable visible={renameVisible} style={{ width: "25vw" }} onHide={() => setRenameVisible(false)}>
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-3">
          <div className="flex flex-column gap-2">
            {getFormErrorMessage("newName")}
            <InputText id="newName" name="newName" checked={formik.values.newName} onChange={(e) => { formik.setFieldValue("newName", e.target.value); }} />
          </div>
          <Button label="Submit" type="submit" />
        </form>
      </Dialog>
      <Dialog header={moveHeaderElement} draggable visible={moveFolderVisible} style={{ width: "35vw" }} onHide={() => { setMoveFolderVisible(false); setSelectedFolder(null); formik2.resetForm(); setRootMove(false); }}>
        <form onSubmit={formik2.handleSubmit} id="move-form-folder">
          <Dropdown id="newFolderMoveF" name="newFolderMoveF" disabled={moveLoading || rootMove} checked={formik2.values.newFolderMoveF} value={selectedFolder} onChange={(e) => { setSelectedFolder(e.value); formik2.setFieldValue("newFolderMoveName", e.value.name); formik2.setFieldValue("newFolderMoveId", e.value.code); }} options={folders} optionLabel="name" placeholder="Select Destination Folder" className="w-full" />
          <div className="flex justify-content-between">
            <div className="flex gap-2">
              <label htmlFor="checkboxMoveRoot">Move to Root</label>
              <Checkbox id="checkboxMoveRoot" name="checkboxMoveRoot" disabled={!parentId || moveLoading} checked={rootMove} onChange={(e) => { setRootMove(!rootMove); formik2.setFieldValue("checkboxMoveRoot", e.checked); }} />
            </div>
            {getFormErrorMessage2("newFolderMoveF")}
          </div>
          <Button label="Submit" type="submit" />
        </form>
      </Dialog>
      <Dialog header="Are you sure?" draggable visible={delVisible} style={{ width: "35vw" }} onHide={() => { setDelVisible(false); }} footer={delFooterContent}>
        <p id="confirmation-text">Clicking 'Yes' will permanently delete this folder along with all its contents.</p>
      </Dialog>
      <Toast ref={toastBottomLeft} position="bottom-left" />
    </div>
  );
};

export default DocScreen;
