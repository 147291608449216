import React from 'react';
import { Card } from 'primereact/card';
import './style/DocumentCard.css';

function SupportCard({ desc1, desc2, linkText, setVisible, redirectLink }) {
  
  const handleClick = () => {
    if (redirectLink) {
      window.open(redirectLink, '_blank');
    } else {
      setVisible();
    }
  };

  return (
    <Card title={desc1} className='support-doc-card'>
      <div className='support-card-text'>
        <div className='doc-sec'>
          <p className='doc-num'>{desc2}</p>
        </div>
      </div>
      <div className='card-doc-link' style={{ cursor: 'pointer' }} onClick={handleClick}>
        <p className='card-doc-link-text'>{linkText}</p>
        <i className="pi pi-angle-right card-doc-link-text" style={{ fontSize: '1.25em' }}></i>
      </div>
    </Card>
  );
}

export default SupportCard;
