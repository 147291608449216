import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { useSelectedContext } from './SelectedContext';
import { VirtualScroller } from 'primereact/virtualscroller';
import './style/ImportDocMenu.css'

const ImportDocMenu = ({ docList, docActivity=false, selectedFolder, identified_doc_count, total_doc_count, data }) => {

    const { setSelectedImportRow, setSelectedProjectRow, setShowComponent } = useSelectedContext();
    const [activeIndex, setActiveIndex] = useState(docActivity ? 1 : 0);

    const sortedActivity = React.useMemo(() => {
        if (data) {
            return data.activityList
                .map(activity => {
                    const date = new Date(activity.date);
                    date.setHours(date.getHours() - 6);
                    return { ...activity, date: date.toISOString() };
                })
                .sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        return [];
    }, [data]);

    const itemTemplate = (item) => {
        return (
            <div className='import-window-activity-item'>
                <p style={{ margin: '0px' }}>{item.description}</p>
                <p className='item-bold'>{new Date(item.date).toLocaleDateString()}</p>
            </div>
        );
    };

    return (
        <div id='import-doc-window'>
            <div id='import-doc-window-header'>
                <p>{docActivity ? selectedFolder : 'Imported documents'}</p>
                <Button icon="pi pi-times" onClick={() => {setShowComponent(false); setSelectedProjectRow(''); setSelectedImportRow('')}} rounded text/>
            </div>
            <TabView id='import-window-tab' activeIndex={activeIndex} >
                {!docActivity && 
                <TabPanel header="Details">
                    <div>
                        <div className='import-window-item-first'>
                        <p>Total documents</p>
                        <p className='item-bold'>{total_doc_count}</p>
                        </div>
                        <div className='import-window-header'>
                            <p>Document types</p>
                        </div>
                        {Object.entries(docList).map(([docType, total_documents], id) => (
                            <div key={id} className='import-window-item'>
                                <p>{docType}</p>
                                <p className='item-bold'>{total_documents}</p>
                            </div>
                        ))}
                        { total_doc_count-identified_doc_count > 0 && 
                        <div className='import-window-item'>
                            <p>Unidentified Docs</p>
                            <p className='item-bold'>{total_doc_count-identified_doc_count}</p>
                        </div>
                        }
                    </div>
                </TabPanel>}
                <TabPanel header="Activity">
                    {sortedActivity !== '' &&
                        <div className="flex justify-content-center">
                            <VirtualScroller items={sortedActivity} itemSize={50} itemTemplate={itemTemplate} className="" style={{ width: '100%', height: '38em' }} />
                        </div>
                    }
                </TabPanel>
            </TabView>
        </div>
    );
};

export default ImportDocMenu;