import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SelectedProvider } from './components/SelectedContext';
import "./index.css"

const rootElement = document.getElementById('root');
const appRoot = ReactDOM.createRoot(rootElement);
appRoot.render(
    <SelectedProvider>
        <App />
    </SelectedProvider>
);