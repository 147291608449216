import React from 'react';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import './style/DocumentCard.css';

function DocumentCardSkeleton() {

  return (
    <Card className='doc-flex-item docCardItemSkel '>
        <div className='card-text'>
            <div className='doc-sec-skel'>
                <Skeleton className='doc-title' width='14rem' height='1.5rem'/>
                <Skeleton className='doc-num' width='7rem' height='1rem'/>
            </div>
            <div className='doc-sec-skel'>
                <Skeleton className='doc-title' width='14rem' height='1.5rem'/>
                <Skeleton className='doc-num' width='7rem' height='1rem'/>
            </div>
        </div>
        <div className='card-doc-link-skel'>
            <Skeleton className='card-doc-link-text-skel' width='7rem' height='1.5rem'/>
            <i className="pi pi-angle-right card-doc-link-text" style={{'fontSize': '1.25em'}}></i>
        </div>
    </Card>
  )
}

export default DocumentCardSkeleton