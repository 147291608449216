import React, { useState, useRef, useEffect, lazy } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { useSelectedContext } from "./SelectedContext";
import ProgressWindow from "./ProgressWindow";
import api from "../api";
import "./style/PanelMenu.css";
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Divider } from 'primereact/divider';

const DocumentMenu = () => {
  const { setExtractmin, setExtractProgressVis, fcSwitch, setShowComponent, setSelectedProjectRow } = useSelectedContext();
  const [socket, setSocket] = useState(null);
  const importButtonRef = useRef(null);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashing, setFlashing] = useState(false);
  const [diaDisabled, setDisabled] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const toastBottomLeft = useRef(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const [data, setData] = useState([]);
  const [totalFiles, setTotalF] = useState(0);
  const [docTypes, setDocTypes] = useState([]);
  const [docType, setDocType] = useState({});
  const [newFolderName, setFolderName] = useState('');

  useEffect(() => {
    getDocumentCount();
  }, [sessionStorage.getItem("selectedProjectId"), fcSwitch]);

  const showToast = (message, severity) => {
    toastBottomLeft.current.show({
      severity,
      summary: message,
      life: 3000,
    });
  };

  const handleMenuItemClick = (folderPath, folderIdPath) => {
    sessionStorage.setItem("selectedListStyle", "left");
    sessionStorage.setItem("selectedView", "Source Documents");
    sessionStorage.setItem("currentScreen", "File Source");
    sessionStorage.setItem("folderPath", folderPath);
    sessionStorage.setItem("folderIdPath", folderIdPath);
    setShowComponent(false);
    setSelectedProjectRow(null);
  };

  const getDocumentCount = async () => {
    const projectId = sessionStorage.getItem("selectedProjectId");
    const token = sessionStorage.getItem("token");

    if (token && projectId) {
      try {
        setLoading(true);
        const response = await api.get(
          `/projects/get-folder-counts/${parseInt(projectId, 10)}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        buildMenuItems(response.data);
      } catch (error) {
        console.error("Failed to get folder counts:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const itemRenderer = (item) => (
    <Link
      className="flex align-items-start px-3 py-2 cursor-pointer"
      to={item.url}
      onClick={() => handleMenuItemClick(item.folderPath, item.folderIdPath)}
    >
      {item.label}
    </Link>
  );

  const buildMenuItems = (folderData) => {
    const menuItems = folderData.map((docType) => {
      if (
        docType.items[0].folder_name !== "Coming soon..." &&
        docType.items[0].folder_name !== "No folders"
      ) {
        return {
          label: docType.document_type,
          items: docType.items.map((folder) => ({
            label: (
              <div>
                {folder.folder_name} ({folder.file_count})
              </div>
            ),
            folderPath: folder.folderPath,
            folderIdPath: folder.folderIdPath,
            url: `/sourcedocs/${encodeURIComponent(
              docType.document_type.toLowerCase()
            )}/${docType.document_type_id}/${encodeURIComponent(
              folder.folder_name
            )}/${folder.folder_id}`,
            dt: docType,
            folder: folder,
            template: itemRenderer,
          })),
        };
      } else {
        return {
          label: docType.document_type,
          items: docType.items.map((folder) => ({
            label: <div>{folder.folder_name}</div>,
          }))
        };
      }
    });
    setMenuItems(menuItems);
  };

  const onTemplateSelect = (e) => {
    setTotalF(e.files.length);
    setFlashing(true);
  };

  const onTemplateUpload = (e) => {
    let files = [];
    setExtractProgressVis(true);
    setExtractmin(false);
    setVisible(false);
    try {
      e.xhr.onload = () => {
        if (e.xhr.status === 200) {
          const response = JSON.parse(e.xhr.response);
          response.responses.forEach((item) => {
            const fileName = item.s3_key.split("/").pop();
            const fileId = item.file_id;
            let modifiedFile = {
              name: fileName,
              id: fileId,
              extractionComplete: false,
              extractionFailed: false,
            };
            files.push(modifiedFile);
          });
        } else {
          console.error("Upload failed. Status:", e.xhr.status);
        }
      };
      setUploadedFiles((prevFiles) => prevFiles.concat(files));
      showToast("File(s) Uploaded", "success");
    }
    catch (error) {
      console.error("Failed to read uplaod response:", error);
    }
    setDocType({});
    setFolderName('');
  };

  const getDocTypes = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await api.get(
        `/doc-types/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const supportedIDs = [1]
      const filteredData = response.data.filter(item => supportedIDs.includes(item.id));
      setDocTypes(filteredData);
    } catch (error) {
      console.error("Failed to get doc types:", error);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("userID")) {
      const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      const protocol = backendEndpoint.startsWith("https") ? "wss" : "ws";
      const newSocket = new WebSocket(
        `${protocol}:${process.env.REACT_APP_WSS_ENDPOINT}/files/ws?user_id=${parseInt(
          sessionStorage.getItem("userID"),
          10
        )}`
      );
      setSocket(newSocket);
    }
    getDocTypes();
  }, []);

  const handleUpdate = (updatedData) => {
    const data = JSON.parse(updatedData);
  
    setData((prevData) => {
      const updatedIndex = prevData.findIndex(item => item.file_id === data.file_id);
      
      if (updatedIndex === -1) {
        return [...prevData, data];
      }
  
      const updatedDataList = prevData.map((item, index) => 
        index === updatedIndex 
          ? {
              ...item,
              document_identified: data.document_identified ?? item.document_identified,
              extraction_completed: data.extraction_completed ?? item.extraction_completed,
            }
          : item
      );
  
      return updatedDataList;
    });
  };
  
  useEffect(() => {
    if (socket) {
      socket.onopen = () => console.log("WebSocket connected");
  
      let batchId = 0;
  
      socket.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        
        handleUpdate(event.data);
  
        if (batchId === 0) {
          batchId = eventData.batch_id;
        }
  
        setUploadedFiles((prevFiles) => {
          const updatedFiles = prevFiles.map((item) =>
            item.id === eventData.file_id
              ? {
                  ...item,
                  extractionComplete: eventData.extraction_completed,
                  extractionFailed: eventData.extraction_failed,
                }
              : item
          );
  
          const allExtractionComplete = updatedFiles.every(item => item.extractionComplete);
  
          if (allExtractionComplete && updatedFiles.length > 0) {
            deleteFailedItems();
            setExtractProgressVis(false);
            showToast("Extraction Successful", "success");
            return [];
          }
  
          return updatedFiles;
        });
      };
  
      socket.onclose = () => console.log("WebSocket disconnected");
  
      socket.onerror = (error) => console.error("WebSocket error:", error);
    }
  }, [socket, uploadedFiles]);

  const deleteFailedItems = async () => {
    const failedIds = uploadedFiles
      .filter((file) => file.extractionFailed)
      .map((file) => file.id);

    if (failedIds.length > 0) {
      try {
        const token = sessionStorage.getItem("token");
        await api.post(
          "/files/delete-files",
          { file_ids: failedIds },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.error("Failed to delete failed files:", error);
      }
    }
  };

  const onTemplateRemove = (file, callback) => {
    callback();
    setTotalF(totalFiles-1);
  };

  const onTemplateClear = () => {
    setFlashing(false);
    setTotalF(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={className} style={{backgroundColor: "transparent", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <div>
          {chooseButton}
          {uploadButton}
          {cancelButton}
        </div>
        <p>{totalFiles} File(s) Selected</p>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const sizeValue = parseFloat(props.formatSize.split(' ')[0]).toFixed(2);
    const sizeUnit = props.formatSize.split(' ')[1];

    return (
      <div className="file-description">
        {file.name}
        <div className="file-info">
          <Tag value={`${sizeValue} ${sizeUnit}`} severity="warning" className="px-3 py-2"/>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
            onClick={() => onTemplateRemove(file, props.onRemove)}
            disabled={diaDisabled}
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File(s) Here
        </span>
      </div>
    );
  };

  const handleCustomUpload = (event) => {
    setDisabled(true);
    event.xhr.setRequestHeader(
      "Authorization",
      "Bearer " + sessionStorage.getItem("token")
    );
    return event;
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-open",
    label: "Select files",
    iconOnly: false,
    className: `custom-choose-btn p-button-rounded p-button-outlined ${
      diaDisabled ? "disabled-button" : ""
    }`,
  };

  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: false,
    className: `custom-upload-btn p-button-success p-button-rounded p-button-outlined ${
      flashing && docType.id && newFolderName ? "flashing-button" : ""
    } ${diaDisabled || !docType.id || !newFolderName ? "disabled-button" : ""}`,
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    label: "Clear all",
    iconOnly: false,
    className: `custom-cancel-btn p-button-danger p-button-rounded p-button-outlined ${
      diaDisabled ? "disabled-button" : ""
    }`,
  };

  return (
    <React.Fragment>
      {sessionStorage.getItem("currentScreen") !== "File Database" &&
        sessionStorage.getItem("currentScreen") !== "Folder Database" &&
        sessionStorage.getItem("projectSelected") === "true" && (
          <div id="panel-page">
            <div id="custom-panel-menu">
              <span className="p-input-icon-left">
                <Button
                  ref={importButtonRef}
                  className="import-button"
                  outlined
                  label="Import Documents"
                  icon="pi pi-download"
                  style={{ width: "224px", backgroundColor: "EBF0F5" }}
                  onClick={() => {
                    setVisible(true);
                    setDisabled(false);
                  }}
                />
                <Dialog
                  header="Import Documents"
                  focusOnShow={false}
                  draggable
                  visible={visible}
                  style={{ width: "50vw" }}
                  onHide={() => {
                    setVisible(false);
                    setFlashing(false);
                    setTotalF(0);
                    setDocType({});
                    setFolderName('');
                  }}
                >
                  <div>
                    <div id="folder-info-input">
                      <div className="folder-sect">
                        <p className="folder-info-p">Name the folder that will contain your document(s).</p>
                        <InputText placeholder="Folder name" value={newFolderName} onChange={(e) => setFolderName(e.target.value)} />
                      </div>
                      <div className="folder-sect">
                        <p className="folder-info-p">Select the type of document(s) that you are importing.</p>
                        <Dropdown value={docType} onChange={(e) => setDocType(e.value)} options={docTypes} optionLabel="document_type" 
                          placeholder="Select a Document Type" className="w-full" />
                      </div>
                    </div>
                    <Divider />
                    <p className="import-p">Import the document(s) that you would like to extract.</p>
                    <Toast ref={toast}></Toast>
                    <FileUpload
                      ref={fileUploadRef}
                      name="files"
                      url={`${process.env.REACT_APP_BACKEND_ENDPOINT}/files/upload_in_chunks?project_id=${parseInt(
                        sessionStorage.getItem("selectedProjectId"), 10
                      )}&doc_id=${parseInt(
                        docType.id, 10
                      )}&folder_name=${newFolderName}`}
                      multiple
                      accept="application/pdf"
                      onUpload={onTemplateUpload}
                      onSelect={onTemplateSelect}
                      onError={onTemplateClear}
                      onClear={onTemplateClear}
                      headerTemplate={headerTemplate}
                      itemTemplate={itemTemplate}
                      emptyTemplate={emptyTemplate}
                      chooseOptions={chooseOptions}
                      uploadOptions={uploadOptions}
                      cancelOptions={cancelOptions}
                      withCredentials
                      onBeforeSend={handleCustomUpload}
                      disabled={diaDisabled}
                    />
                  </div>
                </Dialog>
              </span>
              {loading ? (
                <div className="panel-skeleton">
                  {[...Array(8)].map((_, index) => (
                    <Skeleton
                      key={index}
                      className="w-full md:w-14rem"
                      height="59.2px"
                    />
                  ))}
                </div>
              ) : (
                <PanelMenu model={menuItems} className="w-full md:w-14rem panel-menu-class" />
              )}
            </div>
          </div>
        )}
      <ProgressWindow itemList={uploadedFiles} />
      <Toast ref={toastBottomLeft} position="bottom-left" />
    </React.Fragment>
  );
};

export default DocumentMenu;
