import React from 'react';
import { Button } from 'primereact/button';
import './style/ItemControls.css'

const ItemControls = ({ selectedItems, handleMoveClick, handleDeleteClick, isAdmin, handleDownloadClick, handleVerifyClick, handleUnVerifyClick }) => {

    return (
        <div id='doc-item-toast-container'>
            <div id='doc-item-toast' className='fadeinup animation-duration-200'>
                <p className='toast-p'><b>{selectedItems.length} file(s) selected</b></p>
                {isAdmin && <Button className='toast-flex-item' icon="pi pi-trash" label="Delete" severity="info" text onClick={() => {handleDeleteClick()}}/>}
                <Button className='toast-flex-item' icon="pi pi-download" label="Download" severity="info" text onClick={() => {handleDownloadClick()}}/>
                <Button className='toast-flex-item' icon="pi pi-file-export" label="Move to folder" severity="info" text onClick={() => {handleMoveClick()}}/>
                <Button className='toast-flex-item' icon="pi pi-check-square" label="Mark as verified" severity="info" text onClick={() => {handleVerifyClick()}}/>
                <Button className='toast-flex-item' icon="pi pi-undo" label="Mark as unverified" severity="info" text onClick={() => {handleUnVerifyClick()}}/>
            </div>
        </div>
    );
};

export default ItemControls;