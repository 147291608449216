import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelectedContext } from '../components/SelectedContext';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import api from '../api';
import './style/DataDocScreen.css';
import { Skeleton } from 'primereact/skeleton';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Document, Page } from 'react-pdf';
import { Button } from 'primereact/button';

const DataDocScreen = () => {

    const { selectedDBRow, visibleColumns, setDataToExport, dataToExport, globalFilterFields, globalFilters, setGlobalFilters, setTableData, tableData, setNonFilterData, skelData } = useSelectedContext();
    const [statuses, setDiams] = useState([]);
    const [lastNewData, setLastNewData] = useState(null);
    const [formattedAll, setFormattedAll] = useState([]);
    const [diameters, setDiameters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { selectedStationLabel, selectedStationId, selectedDocType } = useParams();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [pdfUrl, setPdfURL] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    
    useEffect(() => {
        getAllDocs()
    }, [selectedStationId]);

    const getAllDocs = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/mtr/mtr-attributes/${parseInt(selectedStationId,10)}`, { headers });
            const responseDiam = await api.get('/mtr/diameter', { headers });
            setDiams(responseDiam.data.map(item => item + '"'))
            setDiameters(responseDiam.data.map(item => item + '"'))
            setFormattedAll(formatMTR(response.data));
        } catch (error) {
            console.error('Failed to get attributes: ', error);
        }
        finally {
            setIsLoading(false);
        }
    }

    const formatMTR = (data) => {
        return data.map((item, index) => {
            if (item.file_Name.includes("/")) {
                const fileNameParts = item.file_Name.split("/");
                item.file_Name = fileNameParts.pop();
            }
            if (item.description && item.description.includes("WT")) {
                const indexWT = item.description.indexOf(" WT");
                if (indexWT !== -1) {
                    const substringBeforeWT = item.description.substring(0, indexWT).trim();
                    const lastSpaceIndex = substringBeforeWT.lastIndexOf(" ");
                    const wallThickness = substringBeforeWT.substring(lastSpaceIndex + 1);
                    item.width = wallThickness;
                }
            }
            let parsedDate = null;
            if (item.date) {
                parsedDate = new Date(item.date);
                parsedDate.setHours(parsedDate.getHours() - 6);
            }
            else {
                parsedDate = null
            }
            
            let parsedDateM = new Date(item.created_at);       
            if (!isNaN(parsedDateM.getTime())) {
                parsedDateM.setHours(parsedDateM.getHours() - 6);
            } else {
                parsedDateM = null;
            }

            item.date_created = parsedDateM;
            item.date = parsedDate;
            return item;
        });
    };

    useEffect(() => {
        const exampleObject = formattedAll[0];
        let columnHeaders = [];

        if (exampleObject) {
            columnHeaders = Object.keys(exampleObject).filter(key => key !== "id");
        }
        else {
            columnHeaders = []
        }
        
        setNonFilterData(formattedAll);
        setTableData(formattedAll);
        setDataToExport(formattedAll);
        setLastNewData(formattedAll);
        setGlobalFilters(columnHeaders);
    }, [formattedAll]);

    useEffect(() => {
        if (lastNewData && Array.isArray(visibleColumns) && visibleColumns.length > 0) {
            const filteredData = lastNewData.map((item) => {
                const filteredItem = {
                    count: item.count
                };
                for (const col of visibleColumns) {
                  if (item.hasOwnProperty(col.field)) {
                    filteredItem[col.field] = item[col.field];
                  }
                }
                filteredItem.date_created = item.date_created;
                return filteredItem;
            });
            setDataToExport(filteredData);
        }

    }, [visibleColumns]);

    const tabviewStyle = {
        marginTop: '10px'
    };

    const textEditor = (options) => {
        const { value } = options;
        const defaultValue = value !== null && value !== undefined ? value : '';

        return (
            <InputText
                style={{ width: '100%' }}
                type="text"
                value={defaultValue}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    const dropdownEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                className="md:w-10rem"
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Diameter"
            />
        );
    };

    const dateEditor = (options) => {
        const handleDateChange = (e) => {
            let selectedDate = new Date(e.value);
            selectedDate.setHours(selectedDate.getHours() + 6);
            options.editorCallback(selectedDate);
        };
    
        return (
            <Calendar 
                value={options.value} 
                onChange={handleDateChange} 
                dateFormat="mm/dd/yy" 
                mask="99/99/9999" 
            />
        );
    };    

    const handleDataTableUpdate = (newData) => {
        setLastNewData(newData);
        if (dataToExport) {
            const filteredData = newData.map((item) => {
                const filteredItem = {
                    count: item.count
                };
                for (const col of visibleColumns) {
                  if (item.hasOwnProperty(col.field)) {
                    filteredItem[col.field] = item[col.field];
                  }
                }
                filteredItem.date_created = item.date_created;
                return filteredItem;
            });
            setDataToExport(filteredData);
        }
    };

    const diameterRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={diameters}
                itemTemplate={diameterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const diameterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => {options.filterCallback(e.value, options.index)}} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
    }

    const formatFileResponse = (data) => {
        return data.map((item, index) => {
          const parsedDate = new Date(item.created_at);
          if (!isNaN(parsedDate.getTime())) {
            const formattedDate = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
            parsedDate.setHours(parsedDate.getHours() - 6);
      
            return {
              id: item.id,
              file_name: item.file_name,
              date_created: formattedDate,
              document_size: item.document_size,
              representative: item.representative,
              extracted: item.extracted,
              sort_id: index + 1
            };
          } else {
            
            return {
              id: item.id,
              file_name: item.file_name,
              date_created: 'Invalid Date',
              document_size: item.document_size,
              representative: item.representative,
              extracted: item.extracted,
              sort_id: index + 1
            };
          }
        });
      };

    const onRowEditComplete = (e) => {
        let { newData } = e;
        updateItem(newData);
    };

    const updateItem = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const file_response = await api.put(`/mtr/update-mtr/${data.id}`, data, { headers });
            const updatedFormattedAll = formattedAll.map(item =>
                item.id === data.id ? { ...item, ...data } : item
            );
            setFormattedAll(updatedFormattedAll);
            const file = await api.get(`/files/get-file/${file_response.data.file_id}`, { headers });
            const selectedStationMod = selectedStationLabel.replace(/%20/g, ' ');
            const requestBodyAct = {
                'type_id': 6,
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "description": `manually updated an attribute in file: ${file.data.file_name.split('/').pop()}`,
                "meta_data": {
                    "folder_id": parseInt(selectedStationId,10),
                    "folder_name": selectedStationMod,
                    "doc_type": file.data.document_type_id,
                    "file_id": file.data.id,
                    "old_data": data,
                    "doc_type_name": selectedDocType
                }
            }
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
        } catch (error) {
            console.error('Failed to update item: ', error);
        }
    }

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const skelTemplate = () => {
        return (
            <Skeleton width='5rem'/>
        );
    };

    const fileTitleTemplate = (rowData) => {
        let name = '';
        if (rowData.file_Name.includes('tmp')) {
            const parts = rowData.file_Name.split('/');
            name = parts[parts.length - 1];
        } else {
            name = rowData.file_Name;
        }
        return (
            <div>
                <a className='title-preview-link' onClick={() => previewFile(name)}>{name}</a>
            </div>
        );
    };

    const previewFile = async (name) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/files/client/${sessionStorage.getItem('selectedClientId')}/project/${sessionStorage.getItem('selectedProjectId')}/file/${name}`, { headers });
            setPdfURL(response.data.pre_signed_url);
            setPreviewVisible(true);
        } catch (error) {
            console.error('Failed to preview file: ', error);
        }
    };

    const toggleOrientation = () => {
        const newRotation = (rotation + 90) % 360;
    
        setRotation(newRotation);
    
        if (newRotation === 0) {
            setRotation(0);
        }
    };
    
    const handleZoomIn = () => {
        setZoom(zoom + 0.5);
    };

    const handleZoomOut = () => {
        setZoom(Math.max(zoom - 0.50, 0.50));
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
  
    return (
        <div style={tabviewStyle} id='database-table'>
            {(isLoading ? (
                <DataTable showGridlines value={skelData}>
                    <Column header="File Name" body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                    <Column body={skelTemplate}/>
                </DataTable>
            ) : (
                visibleColumns.length > 0 && (
                    <DataTable scrollable className='database-table' showGridlines value={tableData} selectionMode='single' editMode="row" selection={selectedDBRow} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} removableSort globalFilterFields={globalFilters} filters={globalFilterFields} emptyMessage="No items found." onValueChange={handleDataTableUpdate} onRowEditComplete={onRowEditComplete}>
                        {visibleColumns.map((col) => (
                            col.field === "diameter" ? (
                                <Column key={col.field} sortable filter header="Diameter" filterElement={diameterRowFilterTemplate} showFilterMatchModes={false} field="diameter" editor={(options) => dropdownEditor(options)}/>
                            ) : col.field === "date_created" ? (
                                <Column key={col.field} sortable filter dataType="date" header="Date Created" filterElement={dateFilterTemplate} field="date_created" filterField="date_created" body={(rowData) => {
                                    return (
                                        <span>
                                            {rowData.date_created ? rowData.date_created.toLocaleDateString('en-US') : ''}
                                        </span>
                                    );
                                }}></Column>
                            ) : col.field === "date" ? (
                                <Column key={col.field} sortable filter dataType="date" header="Date Manufactured" filterElement={dateFilterTemplate} field="date" editor={(options) => dateEditor(options)} filterField="date" body={(rowData) => {
                                    return (
                                        <span>
                                            {rowData.date ? rowData.date.toLocaleDateString('en-US') : ''}
                                        </span>
                                    );
                                }}></Column>
                            ) : col.field === "file_Name" ? (
                                <Column key={col.field} sortable filter field={col.field} filterField={col.field} header={col.header} editor={(options) => textEditor(options)} body={fileTitleTemplate}/>
                            ) : (
                                <Column key={col.field} sortable filter field={col.field} filterField={col.field} header={col.header} editor={(options) => textEditor(options)}/>
                            )
                        ))}
                        <Column header="Edit" rowEditor={allowEdit} headerStyle={{ width: '4%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                )
            ))}
            <Dialog header="PDF Preview" maximizable draggable visible={previewVisible} onHide={() => setPreviewVisible(false)}>
                <div className='pdf-buttons'>
                    <Button label='Rotate' icon="pi pi-refresh" iconPos="right" onClick={toggleOrientation}/>
                    <Button label='Zoom In' icon="pi pi-plus" iconPos="right" onClick={handleZoomIn} />
                    <Button label='Zoom Out' icon="pi pi-minus" iconPos="right" onClick={handleZoomOut} />
                    <Button label='Previous Page' disabled={pageNumber <= 1} icon="pi pi-chevron-left" iconPos="right" onClick={previousPage} />
                    <Button label='Next Page' disabled={pageNumber >= numPages} icon="pi pi-chevron-right" iconPos="right" onClick={nextPage} />
                </div>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} rotate={rotation} scale={zoom}/>
                </Document>
            </Dialog>
        </div>
    )
  };
  
  export default DataDocScreen;