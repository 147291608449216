import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSelectedContext } from '../components/SelectedContext';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import api from '../api';
import './style/ImportExport.css';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';

const ImportExport = () => {

    const { updateSelectedView, setShowComponent, setSelectedUnclassRow, selectedImportRow, setSelectedImportRow, skelData } = useSelectedContext();
    const [activeIndex, setActiveIndex] = useState(0);
    const [representativesImp, setRepresentativesImp] = useState([]);
    const [representativesExp, setRepresentativesExp] = useState([]);
    const [filters, setFilters] = useState({
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        file_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        folder_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        document_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        date_imported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        date_exported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        representativeExp: { value: null, matchMode: FilterMatchMode.IN },
        import_status: { value: null, matchMode: FilterMatchMode.IN },
        document_types_list: { value: null, matchMode: FilterMatchMode.IN },
        total_documents: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const [importData, setImportData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [isLoadingImports, setIsLoadingImports] = useState(true);
    const [isLoadingExports, setIsLoadingExports] = useState(true);
    const [expandedRows, setExpandedRows] = useState(null);
    const [statVisible, setStatVisible] = useState(false);
    const [statData, setStatData] = useState({});

    useEffect(() => {
        if (sessionStorage.getItem('authenticated')) {
            updateSelectedView('Source Documents');
        }
        getImports()
        getExports()
        if (sessionStorage.getItem('searchName') && sessionStorage.getItem('searchName') !== '') {
            setFilters({
                name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                file_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                folder_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                document_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                date_imported: { operator: FilterOperator.AND, constraints: [{ value: new Date(sessionStorage.getItem('searchDate')), matchMode: FilterMatchMode.DATE_IS }] },
                date_exported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                representative: { value: [sessionStorage.getItem('searchName')], matchMode: FilterMatchMode.IN },
                representativeExp: { value: null, matchMode: FilterMatchMode.IN },
                import_status: { value: null, matchMode: FilterMatchMode.IN },
                document_types_list: { value: null, matchMode: FilterMatchMode.IN },
                total_documents: { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchLen'), matchMode: FilterMatchMode.EQUALS }] }
            });
            sessionStorage.removeItem('searchName')
            sessionStorage.removeItem('searchDate')
            sessionStorage.removeItem('searchLen')
        }
        if (sessionStorage.getItem('searchNameRep') && sessionStorage.getItem('searchNameRep') !== '') {
            setActiveIndex(1)
            setFilters({
                name: { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchFolder'), matchMode: FilterMatchMode.STARTS_WITH }] },
                file_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                folder_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                document_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                date_imported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                date_exported: { operator: FilterOperator.AND, constraints: [{ value: new Date(sessionStorage.getItem('searchDate')), matchMode: FilterMatchMode.DATE_IS }] },
                representative: { value: null, matchMode: FilterMatchMode.IN },
                representativeExp: { value: ['Ryan Craig'], matchMode: FilterMatchMode.IN },
                import_status: { value: null, matchMode: FilterMatchMode.IN },
                document_types_list: { value: null, matchMode: FilterMatchMode.IN },
                total_documents: { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchLen'), matchMode: FilterMatchMode.EQUALS }] }
            });
            sessionStorage.removeItem('searchNameRep')
            sessionStorage.removeItem('searchDate')
            sessionStorage.removeItem('searchFolder')
        }
    }, []);



    const getImports = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/batches/get-imports/${parseInt(sessionStorage.getItem('selectedProjectId'),10)}`, { headers });
            setRepresentativesImp(getAllRepresentatives(response.data));
            setImportData(formatFoldersImport(response.data).sort((a, b) => b.date_imported - a.date_imported))
        } catch (error) {
            console.error('Failed to get folders: ', error);
        }
        finally {
            setIsLoadingImports(false);
        }
    }

    const getAllRepresentatives = (data) => {
        const uniqueRepresentatives = Array.from(new Set(data.map(item => item.imported_by)));
        return uniqueRepresentatives;
    };
    const getAllRepresentativesExp = (data) => {
        const uniqueRepresentatives = Array.from(new Set(data.map(item => item.representative)));
        return uniqueRepresentatives;
    };

    const formatFoldersImport = (data) => {
        return data.map((item) => {
          const parsedDate = new Date(item.date_imported);
      
          if (!isNaN(parsedDate.getTime())) {
            parsedDate.setHours(parsedDate.getHours() - 6);
      
            return {
              total_documents: item.document_count,
              date_imported: parsedDate,
              document_size: (item.document_size / Math.pow(1024, 2)).toFixed(2) + " MB",
              representative: item.imported_by,
              document_type: item.document_type,
              folder_name: item.folder_name,
              files: item.files
            };
          } else {
            console.error('Invalid date string:', item.date_created);
      
            return {
              total_documents: item.document_count,
              date_imported: 'Invalid Date',
              document_size: (item.document_size / Math.pow(1024, 2)).toFixed(2) + " MB",
              representative: item.imported_by,
              document_type: item.document_type,
              folder_name: item.folder_name,
              files: item.files
            };
          }
        });
    };

    const getExports = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/exports/${parseInt(sessionStorage.getItem('selectedProjectId'),10)}`, { headers });
            setRepresentativesExp(getAllRepresentativesExp(response.data));
            setExportData(formatFoldersExport(response.data).sort((a, b) => b.date_exported - a.date_exported))
        } catch (error) {
            console.error('Failed to get folders: ', error);
        }
        finally {
            setIsLoadingExports(false);
        }
    }

    const formatFoldersExport = (data) => {
        return data.map((item) => {
          const parsedDate = new Date(item.date_exported);
      
          if (!isNaN(parsedDate.getTime())) {
            parsedDate.setHours(parsedDate.getHours() - 6);
      
            return {
                name: item.name,
                document_type: item.document_type,
                data: item.data,
                date_exported: parsedDate,
                representativeExp: item.representative
            };
          } else {
            console.error('Invalid date string:', item.date_exported);
      
            return {
                name: item.name,
                document_type: item.document_type,
                data: item.data,
                date_exported: 'Invalid Date',
                representativeExp: item.representative
            };
          }
        });
    };

    const tabviewStyle = {
        marginTop: '5px'
    };

    const closeAllPanels = (index) => {
        if (activeIndex !== index.index) {
            setActiveIndex(index.index);
            setShowComponent(false);
        }
    };

    const resetFilters = () => {
        setFilters({
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            file_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            folder_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            document_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date_imported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            date_exported: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            representative: { value: null, matchMode: FilterMatchMode.IN },
            representativeExp: { value: null, matchMode: FilterMatchMode.IN },
            import_status: { value: null, matchMode: FilterMatchMode.IN },
            document_types_list: { value: null, matchMode: FilterMatchMode.IN },
            total_documents: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => {options.filterCallback(e.value, options.index)}} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
    }

    const fileStatusTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const fileFilterTemplate = (options) => {
        const statusOptions = ['Successful', 'In Progress', 'Failed'];

        return (
            <MultiSelect
                value={options.value}
                options={statusOptions}
                itemTemplate={fileStatusTemplate}
                onChange={(e) => {options.filterApplyCallback(e.value)}}
                placeholder="Any"
                className="p-column-filter"
                // maxSelectedLabels={1}
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const representativeRowFilterTemplateImp = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={representativesImp}
                itemTemplate={representativesItemTemplate}
                onChange={(e) => {options.filterApplyCallback(e.value)}}
                placeholder="Any"
                className="p-column-filter"
                // maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const representativeRowFilterTemplateExp = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={representativesExp}
                itemTemplate={representativesExpItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                // maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;

        return (
            <div className="flex align-items-center gap-2">
                <span>{representative}</span>
            </div>
        );
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const representativeExpBodyTemplate = (rowData) => {
        const representativeExp = rowData.representativeExp;

        return (
            <div className="flex align-items-center gap-2">
                <span>{representativeExp}</span>
            </div>
        );
    };

    const exportTemplate = (rowData) => {
        return (
            <div className="row-button-container">
                <Button className='row-button' onClick={() => exportExcel(rowData.data)}>Regenerate Export</Button>
            </div>
        );
    };

    const exportExcel = (data) => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'filteredData');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date() + EXCEL_EXTENSION);
            }
        });
    };

    const representativesExpItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const skelTemplate = () => {
        return (
            <Skeleton width='5rem'/>
        );
    };

    const StatusView = (rowData) => {

        return (
            <Button className='row-button' onClick={() => {setStatVisible(true);setStatData(rowData)}}>
                View Import Status
            </Button>
        );
    };

    const fileStatus = (rowData) => {
        let statusColor = "";
    
        if (rowData.import_status === "Failed") {
            statusColor = "red";
        } else if (rowData.import_status === "Successful") {
            statusColor = "green";
        } else {
            statusColor = "grey";
        }
    
        return (
            <p style={{ color: statusColor }}>
                {rowData.import_status}
            </p>
        );
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-clearfix" style={{ width: '100%' }}>
                <DataTable showGridlines value={data.files} header={null}>
                    <Column field="file_name" header="File Name"/>
                    <Column body={fileStatus} header="Extraction Status"/>
                </DataTable>
            </div>
        );
    };

    return (
        <div id='table-plus-content' style={tabviewStyle}>
            <TabView activeIndex={activeIndex} style={{ flex: 1}} onTabChange={(activeIndex) => {closeAllPanels(activeIndex); setSelectedImportRow(null); setSelectedUnclassRow(null); resetFilters()}}>
                <TabPanel header="Imports">
                    {isLoadingImports ? (
                        <DataTable className='import-export-table' showGridlines value={skelData}>
                            <Column header="Folder Name" field="folder_name" body={skelTemplate}/>
                            <Column header="Document Type" field="document_type" body={skelTemplate}/>
                            <Column header="Total Documents" field="total_documents" body={skelTemplate}/>
                            <Column header="Date Imported" field="date_imported" body={skelTemplate}/>
                            <Column header="Document Size" field="document_size" body={skelTemplate}/>
                            <Column header="Imported By" field="representative" body={skelTemplate}/>
                            <Column header="Import Info" field="import skel" body={skelTemplate}/>
                        </DataTable>
                    ) : (
                        <DataTable className='import-export-table' scrollable expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No imports." filters={filters} showGridlines value={importData} selectionMode='single' selection={selectedImportRow} onSelectionChange={e => {setShowComponent(false); setSelectedImportRow(null)}} removableSort tableStyle={{ minWidth: '' }}>
                            <Column header="Folder Name" field="folder_name" filter sortable filterField="folder_name"/>
                            <Column header="Document Type" field="document_type" filter sortable filterField="document_type"/>
                            <Column dataType="numeric" header="Total Documents" filter sortable filterField="total_documents" field="total_documents"/>
                            <Column header="Date Imported" dataType="date" filterField="date_imported" field="date_imported" sortable filter filterElement={dateFilterTemplate} body={(rowData) => (
                                <span>{rowData.date_imported.toLocaleDateString('en-US')}</span>
                            )}></Column>
                            <Column field="document_size" sortable header="Document Size"/>
                            <Column sortable filter header="Imported By" field="representative" filterField="representative" showFilterMatchModes={false} style={{ minWidth: '14rem' }}
                                body={representativeBodyTemplate} filterElement={representativeRowFilterTemplateImp} />
                            <Column expander header="Import Info" body={StatusView}/>
                        </DataTable>
                    )}
                </TabPanel>
                <TabPanel header="Exports">
                    {isLoadingExports ? (
                        <DataTable showGridlines value={skelData}>
                            <Column header="Folder Name" field="total_documents" body={skelTemplate}/>
                            <Column header="Document Type" field="date_imported" body={skelTemplate}/>
                            <Column header="Date Exported" field="document_size" body={skelTemplate}/>
                            <Column header="Exported By" field="representative" body={skelTemplate}/>
                        </DataTable>
                    ) : (
                        <DataTable paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No exports." filters={filters} showGridlines value={exportData} selectionMode='single' removableSort tableStyle={{ minWidth: '' }}>
                            <Column header="Folder Name" filterPlaceholder="Search by name" filter sortable field="name"/>
                            <Column header="Document Type" filter sortable field="document_type"/>
                            <Column header="Date Exported" dataType="date" filterField="date_exported" field="date_exported" sortable filter filterElement={dateFilterTemplate}
                                body={(rowData) => {
                                    const date = rowData.date_exported;
                                    if (date instanceof Date && !isNaN(date.getTime())) {
                                        return <span>{date.toLocaleDateString('en-US')}</span>;
                                    }
                                    return <span>Date not available</span>;
                                }}
                            />
                            <Column sortable filter header="Exported By" field="representativeExp" filterField="representativeExp" showFilterMatchModes={false} style={{ minWidth: '14rem' }}
                                body={representativeExpBodyTemplate} filterElement={representativeRowFilterTemplateExp} />
                            <Column header="Download Export" field="data" style={{ minWidth: '14rem' }} body={exportTemplate}/>
                        </DataTable>
                    )}
                </TabPanel>
            </TabView>
            <Dialog header="File Import Status" visible={statVisible} style={{ width: '50vw' }} onHide={() => {if (!statVisible) return; setStatVisible(false); }}>
                <DataTable paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} removableSort className='status-table' scrollable showGridlines value={statData.files} filters={filters}>
                    <Column sortable filter field="file_name" header="File Name"/>
                    <Column filterElement={fileFilterTemplate} filter body={fileStatus} field='import_status' header="Extraction Status" filterField="import_status" showFilterMatchModes={false}/>
                </DataTable>
            </Dialog>
        </div>
    )
};

export default ImportExport;