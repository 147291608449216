import React, { useState, useEffect } from "react";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { SelectedProvider } from "./components/SelectedContext";
import MenuBar from "./components/MenuBar";
import DocumentMenu from "./components/DocumentMenu";
import ImportExport from "./pages/ImportExport";
import Admin from "./pages/Admin";
import Activity from "./pages/Activity";
import Overview from "./pages/Overview";
import Account from "./pages/Account";
import SupportPage from "./pages/SupportPageNew";
import Login from "./pages/Login";
import GroupScreen from "./pages/GroupScreen";
import DocScreen from "./pages/DocScreen";
import DataDocScreen from "./pages/DataDocScreen";
import DatabaseView from "./pages/DatabaseView";
import PasswordReset from "./pages/PasswordReset";
import BreadCrumbRoute from "./components/BreadCrumbRoute";
import "primeflex/primeflex.css";
import "./theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import api from "./api";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const isTokenExpired = () => {
      const token = sessionStorage.getItem("token");
      if (token) {
        const tokenData = JSON.parse(atob(token.split(".")[1]));
        const tokenExpiration = tokenData.exp * 1000;
        if (tokenExpiration > Date.now()) {
          getNewToken(token);
          return true;
        } else {
          sessionStorage.clear();
          sessionStorage.setItem("sessionExpired", true);
          return false;
        }
      }
    };
    setIsAuthenticated(isTokenExpired());
  }, []);

  const getNewToken = async (token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await api.get("/users/new-token", { headers });
      const userResponse = await api.get("/users/current-user-info", {
        headers,
      });
      setIsAdmin(userResponse.data.admin);
      const newToken = response.data.access_token;
      sessionStorage.setItem("token", newToken);
    } catch (error) {
      console.error("Failed to get new token: ", error);
    }
  };

  const AuthenticatedRoutes = () => (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route
        path="/admin"
        element={
          <BreadCrumbRoute
            component={Admin}
            breadcrumbItems={[{ label: "Admin" }]}
            dbToggle={false}
          />
        }
      />
      <Route
        path="/importexport"
        element={
          <BreadCrumbRoute
            component={ImportExport}
            breadcrumbItems={[{ label: "Import & Export" }]}
            dbToggle={false}
          />
        }
      />
      <Route
        path="/activity"
        element={
          <BreadCrumbRoute
            component={Activity}
            breadcrumbItems={[{ label: "Activity" }]}
            dbToggle={false}
          />
        }
      />
      <Route
        path="/account"
        element={
          <BreadCrumbRoute
            component={Account}
            breadcrumbItems={[{ label: "Profile" }]}
            dbToggle={false}
          />
        }
      />
      <Route
        path="/supportpage"
        element={
          <BreadCrumbRoute
            component={SupportPage}
            breadcrumbItems={[{ label: "Help" }]}
            dbToggle={false}
          />
        }
      />
      <Route
        path="/sourcedocs/:selectedDocType/:selectedDocId"
        element={
          <BreadCrumbRoute
            component={GroupScreen}
            breadcrumbItems={[]}
            dbToggle={true}
            isAdmin={isAdmin}
          />
        }
      />
      <Route
        path="/sourcedocs/:selectedDocType/:selectedDocId/:selectedStationLabel/:selectedStationId"
        element={
          <BreadCrumbRoute
            component={DocScreen}
            breadcrumbItems={[]}
            dbToggle={true}
            isAdmin={isAdmin}
          />
        }
      />
      <Route
        path="/databaseview/:selectedDocType/:selectedDocId"
        element={
          <BreadCrumbRoute
            component={DatabaseView}
            breadcrumbItems={[]}
            dbToggle={true}
          />
        }
      />
      <Route
        path="/databaseview/:selectedDocType/:selectedDocId/:selectedStationLabel/:selectedStationId"
        element={
          <BreadCrumbRoute
            component={DataDocScreen}
            breadcrumbItems={[]}
            dbToggle={true}
          />
        }
      />
    </Routes>
  );

  const NonAuthenticatedRoutes = () => (
    <Routes>
      <Route
        path="/"
        element={
          <Login
            setIsAuthenticated={setIsAuthenticated}
            setIsAdmin={setIsAdmin}
          />
        }
      />
      <Route
        path="/password-reset/:selectedUserID"
        element={<PasswordReset />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <Router>
      <SelectedProvider>
        {isAuthenticated ? (
          <>
            <MenuBar
              setIsAuthenticated={setIsAuthenticated}
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
            />
            <div id="page-layout">
              <DocumentMenu isAuthenticated={isAuthenticated} />
              <div id="page-plus-bread">
                <AuthenticatedRoutes />
              </div>
            </div>
          </>
        ) : (
          <div>
            <MenuBar
              setIsAuthenticated={setIsAuthenticated}
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
            />
            <NonAuthenticatedRoutes />
          </div>
        )}
      </SelectedProvider>
    </Router>
  );
};

export default App;
