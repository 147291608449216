import React from 'react';
import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';
import './style/ResultItem.css';

const SearchResult = ({ desc, url, type, handleRedirect }) => {
    const renderIcon = () => {
        switch (type) {
            case 'Document Type':
                return <i className="pi pi-file search-result-icon"></i>;
            case 'Group':
                return <i className="pi pi-folder search-result-icon"></i>;
            case 'Document':
                return <i className="pi pi-tag search-result-icon"></i>;
            default:
                return null;
        }
    };

    return (
        <div>
            <Link className='result-item' to={url} onClick={() => handleRedirect(url, desc, type)}>
                <div className='result-desc'>
                    {renderIcon()}
                    <p className='search-result-text'>{desc}</p>
                </div>
                <i className="pi pi-arrow-right search-result-icon"></i>
            </Link>
            <Divider className='result-underline' />
        </div>
    );
};

export default SearchResult;