import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumbMenu from './BreadCrumbMenu';
import ItemControls from './ItemControls';
import { SelectButton } from 'primereact/selectbutton';
import { useSelectedContext } from './SelectedContext';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import './style/BreadCrumbRoute.css';
import api from '../api';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';

function BreadCrumbRoute({ component: Component, breadcrumbItems, dbToggle = false, isAdmin }) {

    const navigate = useNavigate();
    const options = ['Attributes', 'Source Documents'];
    const viewoptions = [{icon: 'pi pi-bars', value: 'left'}, {icon: 'pi pi-th-large', value: 'right'}];
    const [value, setValue] = useState('');
    const [iconvalue, setIconValue] = useState('left');
    const { selectedDocType, selectedStationLabel, selectedDocId, selectedStationId } = useParams();
    const { setShowComponent, updateSelectedView, updateSelectedListStyle, selectedItems, setSelectedItems, setVisibleColumns, visibleColumns, dataToExport, globalFilterFields, setGlobalFilterFields, nonFilterData, setTableData, setEmptyCellDisabled, fcToggle, setFCToggle, fcCreateVisible, setFcCreateVisible, moveVisible, setMoveVisible, docSearch, setDocSearch, fcSwitch, setFCSwitch, setGroupLoading, setSelectedProjectRow, setChildFileLoading, setChildFolderLoading, childFileLoading, groupLoading } = useSelectedContext();
    const op = useRef(null);
    const [dbIcons, setdbIcons] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...globalFilterFields };
        _filters['global'].value = value;
        setGlobalFilterFields(_filters);
        setGlobalFilterValue(value);
    };
    const [columns, setColumns] = useState([]);
    const toastBottomLeft = useRef(null);
    const [] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);
    const [bcLoading, setBcLoading] = useState(true);
    const [delVisible, setDelVisible] = useState(false);

    const delFooterContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDelVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {setDelVisible(false);handleSubmitDelete()}} autoFocus />
        </div>
    );

    const showMessage = (ref, severity, message) => {
        ref.current.show({ severity: severity, summary: message, life: 3000 });
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    };

    useEffect(() => {
        setSelectedItems([]);
        if (sessionStorage.getItem('currentScreen') !== 'File Database' && sessionStorage.getItem('searchDoc')) {
            sessionStorage.removeItem('searchDoc');
        }
        if (selectedDocId && window.location.href.includes('sourcedocs') && !selectedStationId) {
            sessionStorage.setItem('currentScreen', 'Folder Source')
            sessionStorage.setItem('selectedView', 'Source Documents')
            setValue('Source Documents')
            sessionStorage.removeItem('folderIdPath');
            sessionStorage.removeItem('folderPath');
            setGroupLoading(true);
        }
        else if (selectedDocId && window.location.href.includes('sourcedocs') && selectedStationId) {
            sessionStorage.setItem('currentScreen', 'File Source')
            sessionStorage.setItem('selectedView', 'Source Documents')
            setValue('Source Documents')
            if (selectedStationId && (!sessionStorage.getItem('folderPath') || parseInt(selectedStationId) !== parseInt(storedLocation()))) {
                buildBreadCrumb()
            }
        }
        else if (selectedDocId && window.location.href.includes('databaseview') && !selectedStationId) {
            sessionStorage.setItem('currentScreen', 'Folder Database')
            sessionStorage.setItem('selectedView', 'Attributes')
            setValue('Attributes')
        }
        else if (selectedDocId && window.location.href.includes('databaseview') && selectedStationId) {
            sessionStorage.setItem('currentScreen', 'File Database')
            sessionStorage.setItem('selectedView', 'Attributes')
            setValue('Attributes')
        }
        else if (!selectedDocId && !selectedStationId && window.location.href.includes('activity')) {
            sessionStorage.setItem('currentScreen', 'Activity')
        }
        else if (!selectedDocId && !selectedStationId && window.location.href.includes('importexport')) {
            sessionStorage.setItem('currentScreen', 'Import & Export')
        }
        else if (!selectedDocId && !selectedStationId && window.location.href.includes('account')) {
            sessionStorage.setItem('currentScreen', 'Profile')
        }
        else if (!selectedDocId && !selectedStationId && window.location.href.includes('supportpage')) {
            sessionStorage.setItem('currentScreen', 'Help')
        }

        getNewToken()

    }, [window.location.href]);

    useEffect(() => {
        if (sessionStorage.getItem('currentScreen') === 'File Database' || sessionStorage.getItem('currentScreen') === 'Folder Database') {
            getColumns();
            setDocSearch(false)
            if (sessionStorage.getItem('searchDoc') !== '') {
                const globalFilterFields = {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                };
            
                let hasCreatedAtField = false;
            
                columns.forEach(column => {
                    if (column.field === "created_at" || column.field === "date_created") {
                        hasCreatedAtField = true;
                        globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
                    } else if (column.field === "diameter") {
                        globalFilterFields[column.field] = { value: null, matchMode: FilterMatchMode.IN };
                    } else if (column.field === "file_Name") {
                        globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchDoc'), matchMode: FilterMatchMode.CONTAINS }] };
                    } else {
                        globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
                    }
                });
            
                if (!hasCreatedAtField) {
                    globalFilterFields.date_created = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
                }
                setGlobalFilterFields(globalFilterFields);
                setGlobalFilterValue('');
            }
        }
    }, [docSearch]);

    useEffect(() => {
        const storedView = sessionStorage.getItem('selectedView');
        const storedListStyle = sessionStorage.getItem('selectedListStyle');
        if (storedView !== null) {
            setValue(storedView);
        }
        if (storedListStyle !== null) {
            setIconValue(storedListStyle);
        }
        if (storedView === 'Attributes') {
            if (selectedStationLabel && selectedDocType) {
                setdbIcons(true);
            }
            else if (selectedDocType) {
                setdbIcons(true);
            }
        } else if (storedView === 'Source Documents') {
            if (selectedStationLabel && selectedDocType) {
                setdbIcons(false);
            }
            else if (selectedDocType) {
                setdbIcons(false);
            }
        }

        buildBreadCrumb()
    
        if (sessionStorage.getItem('currentScreen') === 'Folder Source' || sessionStorage.getItem('currentScreen') === 'File Source') {
            setFCToggle(true);
        }
        else {
            setFCToggle(false);
        }
    }, [value, selectedDocId, selectedStationId, sessionStorage.getItem('searchFlip')]);

    useEffect(() => {
        initFilters(columns);
    }, [columns]);

    useEffect(() => {
        if (selectedStationId && sessionStorage.getItem('selectedView') === 'Source Documents'){
            getFolders();
        }
    }, [selectedStationId]);

    const iconItemTemplate = (item) => {
        return (
            <Link to={item.url} onClick={() => handleDTClick(item.partialFoldersArray, item.partialIdArray, item.dt)}>
                <p>{item.label}</p>
            </Link>
        );
    };

    const getFolders = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/folders/get-move-folders?project_id=${parseInt(sessionStorage.getItem('selectedProjectId'),10)}&document_type_id=${parseInt(selectedDocId,10)}`, { headers });
            setFolders(formatFolders(response.data))
        } catch (error) {
            console.error('Failed to get folders: ', error);
        }
    }

    const getNewToken = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/users/new-token`, { headers });
            sessionStorage.setItem('token', response.data.access_token);
        } catch (error) {
            console.error('Failed to get new token: ', error);
            sessionStorage.clear();
            window.location.href = '/';
        }
    }

    const formatFolders = data => {
        const currentFolder = parseInt(selectedStationId, 10);
        const filteredData = data
            .filter(({ folder_id }) => folder_id !== currentFolder)
            .map(({ folder_name, folder_id }) => ({ name: folder_name, code: folder_id }));
    
        return filteredData;
    };

    const buildBreadCrumb = async () => {
        if (selectedStationId && (!sessionStorage.getItem('folderPath') || parseInt(selectedStationId) !== parseInt(storedLocation()))) {
            await getNewPath()
        }
        const capSelectedDocType = capitalizeFirstLetter(selectedDocType);
        if (capSelectedDocType) {
            breadcrumbItems.length = 0
            if (capSelectedDocType !== '') {
                breadcrumbItems.push({ label: capSelectedDocType, url: `/sourcedocs/${selectedDocType}/${selectedDocId}`, dt: true, template: iconItemTemplate});
            }
            if (sessionStorage.getItem('folderPath')) {
                const foldersArray = sessionStorage.getItem('folderPath').split(',');
                const idArray = sessionStorage.getItem('folderIdPath').split(',');
                if (foldersArray.length > 1) {
                    breadcrumbItems.push({ label: "...", url: `/sourcedocs/${selectedDocType}/${selectedDocId}/${foldersArray[foldersArray.length-2]}/${idArray[foldersArray.length-2]}`, partialFoldersArray: foldersArray.slice(0, foldersArray.length + 1), partialIdArray: idArray.slice(0, foldersArray.length + 1), template: iconItemTemplate});
                    breadcrumbItems.push({ label: foldersArray[foldersArray.length - 1], url: `/sourcedocs/${selectedDocType}/${selectedDocId}/${foldersArray[foldersArray.length - 1]}/${idArray[foldersArray.length - 1]}`, partialFoldersArray: foldersArray.slice(0, foldersArray.length + 2), partialIdArray: idArray.slice(0, foldersArray.length + 2), template: iconItemTemplate});
                }
                else {
                    foldersArray.map((item, index) => breadcrumbItems.push({ label: item, url: `/sourcedocs/${selectedDocType}/${selectedDocId}/${item}/${idArray[index]}`, partialFoldersArray: foldersArray.slice(0, index + 1), partialIdArray: idArray.slice(0, index + 1), template: iconItemTemplate}))
                }      
            }
            if (!childFileLoading) {
                setChildFileLoading(true);
                setChildFolderLoading(true);
            }
        }
    };

    useEffect(() => {
        const folderPathFromLocalStorage = sessionStorage.getItem('folderPath');
        if (folderPathFromLocalStorage) {
            const localStorageArrayLength = folderPathFromLocalStorage.split(',').length;
            if (breadcrumbItems.length !== localStorageArrayLength) {
                const capSelectedDocType = capitalizeFirstLetter(selectedDocType);
                if (capSelectedDocType) {
                    breadcrumbItems.length = 0;
                    if (capSelectedDocType !== '') {
                        breadcrumbItems.push({ label: capSelectedDocType, url: `/sourcedocs/${selectedDocType}/${selectedDocId}`, dt: true, template: iconItemTemplate});
                    }
                    if (sessionStorage.getItem('folderPath')) {
                        const foldersArray = sessionStorage.getItem('folderPath').split(',');
                        const idArray = sessionStorage.getItem('folderIdPath').split(',');
                        foldersArray.map((item, index) => breadcrumbItems.push({ label: item, url: `/sourcedocs/${selectedDocType}/${selectedDocId}/${item}/${idArray[index]}`, partialFoldersArray: foldersArray.slice(0, index + 1), partialIdArray: idArray.slice(0, index + 1), template: iconItemTemplate}))
                    }
                }
            }
        }
    }, [breadcrumbItems]);

    const storedLocation = () => {
        const folder_path = sessionStorage.getItem('folderIdPath')
        const folder_id = folder_path.includes(",") ? folder_path.substring(folder_path.lastIndexOf(",") + 1) : folder_path
        return folder_id;
    };

    const getNewPath = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/folders/get-folder-paths/${selectedStationId}`, { headers });
            sessionStorage.setItem('folderPath', response.data[0].folderPath)
            sessionStorage.setItem('folderIdPath', response.data[0].folderIdPath)
        } catch (error) {
            console.error('Failed to get new folder paths: ', error);
        }
    };

    const initFilters = (columns) => {
        const globalFilterFields = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
    
        let hasCreatedAtField = false;
    
        columns.forEach(column => {
            if (column.field === "created_at" || column.field === "date_created") {
                hasCreatedAtField = true;
                globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
            } else if (column.field === "diameter") {
                globalFilterFields[column.field] = { value: null, matchMode: FilterMatchMode.IN };
            } else if (column.field === "file_Name" && sessionStorage.getItem('searchDoc')) {
                globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchDoc'), matchMode: FilterMatchMode.CONTAINS }] };
            } else {
                globalFilterFields[column.field] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
            }
        });
    
        if (!hasCreatedAtField) {
            globalFilterFields.date_created = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
        }
        setGlobalFilterFields(globalFilterFields);
        setGlobalFilterValue('');
    };

    const handleDTClick = (FoldersArray, IdArray, dt) => {
        sessionStorage.setItem('selectedView', 'Source Documents');
        if (dt) {
            sessionStorage.removeItem('folderPath');
            sessionStorage.removeItem('folderIdPath');
        }
        else {
            sessionStorage.setItem('folderPath', FoldersArray.join(','));
            sessionStorage.setItem('folderIdPath', IdArray.join(','));
            sessionStorage.setItem('currentScreen', 'File Source');
            setChildFileLoading(true);
            setChildFolderLoading(true);
        }
        setValue('Source Documents')
        setShowComponent(false)
        setGroupLoading(true)
    };

    const handleSelectChange = (e) => {
        const selectedOption = e.value;
        if (selectedOption) {
            setValue(selectedOption);
            updateSelectedView(selectedOption);
            setShowComponent(false);
            setSelectedProjectRow(null);
            if (selectedOption === 'Attributes') {
                if (sessionStorage.getItem('currentScreen') === 'File Source') {
                    navigate(`/databaseview/${selectedDocType}/${selectedDocId}/${selectedStationLabel}/${selectedStationId}`);
                    sessionStorage.setItem('currentScreen', 'File Database')
                    setdbIcons(true);
                    getColumns();
                }
                else if (sessionStorage.getItem('currentScreen') === 'Folder Source') {
                    navigate(`/databaseview/${selectedDocType}/${selectedDocId}`);
                    sessionStorage.setItem('currentScreen', 'Folder Database')
                    setdbIcons(true);
                    getColumns();
                    if (!groupLoading) {
                        setGroupLoading(true);
                    }
                }
            } else if (selectedOption === 'Source Documents') {
                if (sessionStorage.getItem('currentScreen') === 'File Database') {
                    navigate(`/sourcedocs/${selectedDocType}/${selectedDocId}/${selectedStationLabel}/${selectedStationId}`);
                    sessionStorage.setItem('currentScreen', 'File Source')
                    setdbIcons(true);
                    if (selectedStationId && (!sessionStorage.getItem('folderPath') || selectedStationId !== storedLocation())) {
                        buildBreadCrumb()
                    }
                    if (!childFileLoading) {
                        setChildFileLoading(true);
                        setChildFolderLoading(true);
                    }
                }
                else if (sessionStorage.getItem('currentScreen') === 'Folder Database') {
                    navigate(`/sourcedocs/${selectedDocType}/${selectedDocId}`);
                    sessionStorage.setItem('currentScreen', 'Folder Source')
                    setdbIcons(false);
                }
            }
        }
    };

    const getColumns = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/files/get-columns/${parseInt(selectedDocId,10)}`, { headers });
            const maxVisibleColumns = 12;
            if (sessionStorage.getItem('currentScreen') === 'File Database') {
                const filteredColumns = response.data.filter(column => column.field !== "folder_name");
                setColumns(filteredColumns);
                const visibleColumns = response.data.filter(column => column.field !== "folder_name").slice(0, maxVisibleColumns);
                setVisibleColumns(visibleColumns);
            }
            else {
                setColumns(response.data);
                const visibleColumns = response.data.slice(0, maxVisibleColumns);
                setVisibleColumns(visibleColumns);
            }
        } catch (error) {
            console.error('Failed to get columns: ', error);
        }
        finally {
            setBcLoading(false);
        }
    }

    const handleIconChange = (e) => {
        const selectedOption = e.value;
        if (selectedOption) {
            setIconValue(selectedOption);
            updateSelectedListStyle(selectedOption);
            setSelectedItems([]);
            setShowComponent(false);
            setSelectedProjectRow(null);
        }
    };

    const justifyTemplate = (option) => {
        return <i className={option.icon}></i>;
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
    };

    const exportExcel = () => {
        const hasEntryWithMoreThanTwoFields = dataToExport.some((item) => Object.keys(item).length > 2);
        const newDataToExport = dataToExport.map(item => {
            const newItem = {};
            visibleColumns.forEach(column => {
                newItem[column.field] = item[column.field] !== undefined ? item[column.field] : "";
            });
            return newItem;
        });
        if (hasEntryWithMoreThanTwoFields) {
            import('xlsx').then((xlsx) => {
                const worksheet = xlsx.utils.json_to_sheet(newDataToExport);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });
                saveAsExcelFile(excelBuffer, 'filteredData');
            });
            recordExport(newDataToExport)
        } else {
            alert('No data to export.');
        }
    };

    const recordExport = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const requestBodyExp = {
                "document_type_id": parseInt(selectedDocId,10),
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "meta_data": data
            }
            if (selectedStationId) {
                requestBodyExp.folder_id = selectedStationId;
            }
            const apiUrlExp = `/exports/add-export`;
            const responseExp = await api.post(apiUrlExp, requestBodyExp, { headers });
            const requestBodyAct = {
                'type_id': 1,
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10)
            }
            if (selectedStationId) {
                requestBodyAct.description = `exported data from folder: ${selectedStationLabel.replace(/%20/g, ' ')}`;
                requestBodyAct.meta_data = {"folder_name": selectedStationLabel.replace(/%20/g, ' '), "export_id": responseExp.data.id}
            }
            else {
                const capitalizedDocType = selectedDocType.replace(/\b\w/g, char => char.toUpperCase());
                requestBodyAct.description = `exported top level data from: ${capitalizedDocType.replace(/%20/g, ' ')}`;
                requestBodyAct.meta_data = {"export_id": responseExp.data.id}
            }
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
        } catch (error) {
            console.error('Failed to record export: ', error);
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date() + EXCEL_EXTENSION);
            }
        });
    };

    const clearFilters = () => {
        initFilters(columns);
        setTableData(nonFilterData);
        setEmptyCellDisabled(false);
    }

    const formik = useFormik({
        initialValues: {
            newFolder: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.newFolder === '') {
                errors.newFolder = 'Please name your new folder!';
            }

            return errors;
        },
        onSubmit: () => {
            handleSubmitFC(formik.values);
            formik.resetForm()
        }
    });

    const handleSubmitFC = async (values) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                 Authorization: `Bearer ${token}`,
            };
            const requestBodyAll = {
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "folder_name": values.newFolder,
                "document_type_id": parseInt(selectedDocId,10)
            };
            if (selectedStationId) {
                requestBodyAll.parent_folder_id = selectedStationId;
            }
            const response = await api.post(`/folders/add-folder`, requestBodyAll, { headers });
            const requestBodyAct = {
                'type_id': 7,
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "description": `manually created folder: ${response.data.folder_name}`,
                "meta_data": {
                    "folder_id": response.data.id,
                    "folder_name": response.data.folder_name,
                    "doc_type_id": response.data.document_type_id,
                    "doc_type_name": selectedDocType
                }
            }
            if (selectedStationId) {
                requestBodyAct.meta_data.parent_folder_id = selectedStationId;
                requestBodyAct.meta_data.parent_folder_name = selectedStationLabel;
            }
            else {
                requestBodyAct.meta_data.parent_folder_id = null;
                requestBodyAct.meta_data.parent_folder_name = null;
            }
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
            setFcCreateVisible(false);
            setGroupLoading(true);
            setChildFolderLoading(true);
            setFCSwitch(!fcSwitch);
            showMessage(toastBottomLeft, 'success', 'Folder Created');
        } catch (error) {
            console.error('Failed to update folder name: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const formik2 = useFormik({
        initialValues: {
            newFolderMove: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.newFolderMove === '') {
                errors.newFolderMove = 'Please select a folder!';
            }

            return errors;
        },
        onSubmit: () => {
            handleSubmitMove(formik2.values);
            formik2.resetForm()
        }
    });

    const handleSubmitMove = async (values) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            for (const selectedItem of selectedItems) {
                const requestBody = {
                    folder_id: values.newFolderMove,
                };
                const apiUrl = `/files/update-folder/${selectedItem.id}`;
                const response = await api.put(apiUrl, requestBody, { headers });
                const user = await api.get(`/users/user-info/${response.data.user_id}`, { headers });
                const dest_folder = String(selectedFolder.name).trim();
                const requestBodyAct = {
                    'type_id': 3,
                    "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                    "description": `moved ${selectedItem.file_name} into folder: ${dest_folder}`,
                    "meta_data": {
                        "folder_name": dest_folder,
                        "folder_id": response.data.folder_id,
                        "date_created": response.data.created_at,
                        "created_by": `${user.data.first_name} ${user.data.last_name}`,
                        "doc_type": response.data.document_type_id,
                        "file_id": selectedItem.id,
                        "doc_type_name": selectedDocType
                    }
                }
                const apiUrlAct = `/activity/add-activity`;
                await api.post(apiUrlAct, requestBodyAct, { headers });
            }
            setMoveVisible(false);
            showMessage(toastBottomLeft, 'success', `File move successful!`);
            setSelectedItems([]);
            setSelectedFolder(null);
            setChildFolderLoading(true);
            setChildFileLoading(true);
            formik2.resetForm()
        } catch (error) {
            console.error('Failed to update folder names: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };

    const isFormFieldInvalid2 = (name) => !!(formik2.touched[name] && formik2.errors[name]);

    const getFormErrorMessage2 = (name) => {
        return isFormFieldInvalid2(name) ? <small className="p-error">{formik2.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleMoveClick = () => {
        setMoveVisible(true);
    };

    const handleDeleteClick = () => {
        setDelVisible(true);
    };

    const handleDownloadClick = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const fileNames = selectedItems.map(item => `${sessionStorage.getItem('selectedClient')}/${sessionStorage.getItem('selectedProject')}/${item.file_name}`);
            const requestBody = {
                files: fileNames,
                folder_id: selectedStationId
            };
            const response = await api.post(`/files/download_files`, requestBody, { headers, responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_files.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Failed to download folder: ', error);
        }
    };

    const handleVerifyClick = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const requestBody = {
                files: selectedItems.map(item => item.id)
            };
            await api.put('/files/verify_files', requestBody, { headers });
            setChildFileLoading(true);
            setSelectedItems([]);
        } catch (error) {
            console.error('Failed to mark files as verified: ', error);
        }
    };
    
    const handleUnVerifyClick = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const requestBody = {
                files: selectedItems.map(item => item.id)
            };
            await api.put('/files/un_verify_files', requestBody, { headers });
            setChildFileLoading(true);
            setSelectedItems([]);
        } catch (error) {
            console.error('Failed to mark files as unverified: ', error);
        }
    };

    const handleSubmitDelete = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const file_ids = selectedItems.map(item => item.id);
            const requestBody = {
                "file_ids": file_ids
            };
            await api.post(`/files/delete-files`, requestBody, { headers });
            await Promise.all(selectedItems.map(async (item) => {
                const itemName = item.file_name.includes("/") ? item.file_name.substring(item.file_name.lastIndexOf("/") + 1) : item.file_name;
                const requestBodyAct = {
                    'type_id': 4,
                    "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                    "description": `deleted file: ${itemName}`,
                    "meta_data": {
                        "file_id": item.id,
                        "full_file_name": item.file_name,
                        "project": sessionStorage.getItem('selectedProject'),
                        "client": sessionStorage.getItem('selectedClient')
                    }
                }
                const apiUrlAct = `/activity/add-activity`;
                await api.post(apiUrlAct, requestBodyAct, { headers });
            }));
            await api.delete(`batches/clean-up-batches/${parseInt(sessionStorage.getItem('selectedProjectId'),10)}`, { headers })
            showMessage(toastBottomLeft, 'success', 'File(s) Deleted');
            setSelectedItems([]);
            setChildFileLoading(true);
        } catch (error) {
            console.error('Failed to delete files: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };

    return (
        <React.Fragment>
            <div id={sessionStorage.getItem('currentScreen') === 'File Source' ? 'bread-toggle-tall' : 'bread-toggle'}>
                <div className='breadcrumbSect'>
                    <div id='bread-crumb-menu'>
                        {breadcrumbItems.length > 0 && <BreadCrumbMenu listItems={breadcrumbItems} />}
                    </div>
                    <div id='toggle-and-create-sect'>
                        {dbToggle && (
                            <div id='data-source-toggle'>
                                <SelectButton value={value} onChange={handleSelectChange} options={options} />
                            </div>
                        )}
                        {fcToggle && (
                            <div id='folder-create-toggle'>
                            <Button label="Create Folder" icon="pi pi-plus" iconPos="left" onClick={() => setFcCreateVisible(true)} />
                            </div>
                        )}
                        {(value === 'Source Documents' || selectedStationLabel === 'undefined') && selectedDocType && (
                            <div className='icons'>
                                <SelectButton itemTemplate={justifyTemplate} value={iconvalue} onChange={handleIconChange} options={viewoptions} />
                            </div>
                        )}
                    </div>
                </div>
                <div id={dbIcons ? 'bread-db' : 'toggle-icons'}>
                    <div id="item-controls-container">
                        {selectedItems.length > 0 && <ItemControls selectedItems={selectedItems} handleMoveClick={handleMoveClick} handleDeleteClick={handleDeleteClick} isAdmin handleDownloadClick={handleDownloadClick} handleVerifyClick={handleVerifyClick} handleUnVerifyClick={handleUnVerifyClick}/>}
                    </div>
                    {value === 'Attributes' && (sessionStorage.getItem('currentScreen') === 'Folder Database' || sessionStorage.getItem('currentScreen') === 'File Database') && (
                        <div id='data-table-buttons'>
                            <div className="p-input-icon-right" style={{ width: '18rem' }}>
                                <i className="pi pi-search" />
                                <InputText placeholder="Search table" disabled={bcLoading} type="text" style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange}/>
                            </div>
                            <Button label="Reset Filters" disabled={bcLoading} icon="pi pi-filter" iconPos="right" outlined onClick={() => clearFilters()}/>
                            <Button label="Export" disabled={bcLoading} icon="pi pi-file-excel" onClick={exportExcel} iconPos="right" outlined />
                            <Button label="Configure Table" disabled={bcLoading} icon="pi pi-chevron-down" iconPos="right" outlined onClick={(e) => op.current.toggle(e)}/>
                            <OverlayPanel ref={op}>
                                <div id='column-select'>
                                    <p style={{marginTop:'0px'}}>Pick Columns</p>
                                    <Divider/>
                                    <MultiSelect value={visibleColumns} options={columns} optionLabel="header" onChange={onColumnToggle} className="w-full" />
                                </div> 
                            </OverlayPanel>
                        </div>
                    )}
                </div>
            </div>
            <Component isAdmin={isAdmin}/>
            <Dialog header="Create New Folder" draggable visible={fcCreateVisible} style={{ width: '30vw' }} onHide={() => {setFcCreateVisible(false);formik.resetForm()}}>
                <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                    {getFormErrorMessage('newFolder')}
                    <div className="flex flex-column gap-2">
                        <label htmlFor="newFolder">New Folder Name:</label>
                        <InputText id="newFolder" name="newFolder" checked={formik.values.newFolder} onChange={(e) => {formik.setFieldValue('newFolder', e.target.value)}} />
                    </div>
                    <Button label="Submit" type="submit"/>
                </form>
            </Dialog>
            <Dialog header="Move To Folder" draggable visible={moveVisible} style={{ width: '35vw' }} onHide={() => {setMoveVisible(false);setSelectedFolder(null);formik2.resetForm()}}>
                <form onSubmit={formik2.handleSubmit} id='move-form'>
                    <DataTable paginator rows={5} rowsPerPageOptions={[5, 10]} showGridlines value={selectedItems} selectionMode='single' emptyMessage="No items found." tableStyle={{ minWidth: '' }}>
                        <Column header="Selected Files" field='file_name'></Column>
                    </DataTable>
                    {getFormErrorMessage2('newFolderMove')}
                    <Dropdown id="newFolderMove" name="newFolderMove" checked={formik2.values.newFolderMove} value={selectedFolder} onChange={(e) => {setSelectedFolder(e.value);formik2.setFieldValue('newFolderMove', e.value.code)}} options={folders} optionLabel="name" placeholder="Select Destination Folder" className="w-full" />
                    <Button label="Submit" type="submit"/>
                </form>
            </Dialog>
            <Dialog header="Are you sure?" draggable visible={delVisible} style={{ width: '35vw' }} onHide={() => {setDelVisible(false)}} footer={delFooterContent}>
                <p id='confirmation-text'>Clicking "Yes" will permanently delete these files along with any associated data collected during their extraction process.</p>
            </Dialog>
            <Toast ref={toastBottomLeft} position="bottom-left" />
        </React.Fragment>
    );
  }

export default BreadCrumbRoute