import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Link } from 'react-router-dom';
import { useSelectedContext } from './SelectedContext';

function BreadCrumbMenu({ listItems }) {
    const { setShowComponent, setSelectedProjectRow } = useSelectedContext();

    const handleHomeClick = () => {
        sessionStorage.setItem('currentScreen', 'Home');
        sessionStorage.removeItem('folderPath');
        sessionStorage.removeItem('folderIdPath');
        setShowComponent(false);
        setSelectedProjectRow(null);
    };

    const home = {
        icon: 'pi pi-home',
        template: () => (
            <Link to='/' onClick={handleHomeClick}>
                <span className="pi pi-home"></span>
            </Link>
        )
    };

    return (
        <BreadCrumb model={listItems} home={home} className="breadcrumb-menu" />
    );
}

export default BreadCrumbMenu;


