import React, {useState, useEffect} from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import SearchResult from './SearchResult';
import './style/MenuBar.css';
import { useSelectedContext } from './SelectedContext';
import api from '../api';
import { Link } from 'react-router-dom';

const MenuBar = ({ setIsAuthenticated, isAuthenticated, isAdmin }) => {

  const [visible, setVisible] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState('');
  const { projectSelected, setShowComponent, setSelectedProjectRow, setGroupLoading, setChildFolderLoading } = useSelectedContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      buildMenuItems();
    }
  }, [projectSelected, sessionStorage.getItem('projectSelected'), isAdmin]);

  const buildMenuItems = () => {
    let items = [
      { label: 'Profile', icon: 'pi pi-user', url: '/account', template: itemRenderer },
      { label: 'Help', icon: 'pi pi-question-circle', url: '/supportpage', template: itemRenderer },
      { label: 'Log out', icon: 'pi pi-sign-out', command: handleLogout }
    ];

    if (projectSelected && sessionStorage.getItem('projectSelected') === 'true') {
      items.unshift(
        { label: 'Imports & Exports', icon: 'pi pi-sort-alt', url: '/importexport', template: itemRenderer },
        { label: 'Activity', icon: 'pi pi-bell', url: '/activity', template: itemRenderer }
      );
      getSearchResults();
    }

    if (isAdmin) {
      items.unshift({ label: 'Admin', icon: 'pi pi-star', url: '/admin', template: itemRenderer });
    }

    setMenuItems(items);
  };

  const itemRenderer = (item) => (
    <Link to={item.url} className="flex align-items-center p-menuitem-link" onClick={() => handleMenuClick(item.label)}>
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
    </Link>
  );

  const getSearchResults = async () => {
    try {
        setIsLoading(true);
        const token = sessionStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const responseSearch = await api.get(`/files/get-search-results/${parseInt(sessionStorage.getItem('selectedProjectId'),10)}`, { headers });
        setSearchResults(responseSearch.data);
    } catch (error) {
        console.error('Failed to get search results: ', error);
    } finally {
        setIsLoading(false);
    }
  }

  const handleInputChange = (e) => setSearchString(e.target.value);

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
    window.location.href = '/';
  };

  const clearSessionData = () => {
    sessionStorage.removeItem('folderPath');
    sessionStorage.removeItem('folderIdPath');
    setShowComponent(false);
    setSelectedProjectRow(null);
  };

  const handleMenuClick = (label) => {
    sessionStorage.setItem('currentScreen', label);
    clearSessionData();
  };

  const handleHomeClick = () => {
    sessionStorage.setItem('currentScreen', 'Home');
    clearSessionData();
  };

  const searchRedirect = (path, file_name, type) => {
    if (file_name && file_name !== '' && type === 'Document') {
      let new_file_name = '';
      if (file_name.includes("/")) {
        const pathParts = file_name.split("/");
        new_file_name = pathParts[pathParts.length - 1];
      } else {
        new_file_name = file_name;
      }
      sessionStorage.setItem('searchFile', new_file_name);
    }
    sessionStorage.setItem('selectedListStyle', 'left') 
    if (path.includes('sourcedocs')) {
      sessionStorage.setItem('selectedView', 'Source Documents') 
    }
    if (sessionStorage.getItem('searchFlip') === 'false') {
      sessionStorage.setItem('searchFlip', true)
    }
    else {
      sessionStorage.setItem('searchFlip', false)
    }
    setSearchString('');
    setVisible(false);
    setShowComponent(false);
    setSelectedProjectRow(null);
    if (type === 'Document Type') {
      sessionStorage.setItem('currentScreen', 'Folder Source');
      setGroupLoading(true);
    }
    else {
      sessionStorage.setItem('currentScreen', 'File Source')
      setChildFolderLoading(true);
    }
  };

  const renderSearchDialog = () => (
    <Dialog header={renderHeader()} visible={visible} style={{ width: '50vw', height: '50vh' }} onHide={() => setVisible(false)}>
      <div id="search-results-container">{renderSearchResults()}</div>
    </Dialog>
  );

  const renderHeader = () => (
    <div className="p-input-icon-right" style={{ width: '90%' }}>
      <i className="pi pi-search" />
      <InputText value={searchString} placeholder="Search for documents and document types" type="text" style={{ width: '100%' }} onChange={handleInputChange} />
    </div>
  );

  const renderSearchResults = () => {
    if (searchString === '') return null;

    return (
      <>
        {renderSearchSection('Documents', 'Document')}
        {renderSearchSection('Document Types', 'Document Type')}
        {renderSearchSection('Folders', 'Group')}
      </>
    );
  };

  const renderSearchSection = (header, type) => {
    const filteredResults = searchResults.filter((item) => item.description.toLowerCase().includes(searchString.toLowerCase()) && item.type === type);
    if (filteredResults.length === 0) return null;

    return (
      <div>
        <p className="search-header">{header}</p>
        <Divider className="result-underline" />
        {filteredResults.map((item, id) => (
          <SearchResult key={id} desc={item.description} url={item.url} type={item.type} handleRedirect={searchRedirect} />
        ))}
      </div>
    );
  };

  const renderLogo = () => (
    <Link to="/" onClick={handleHomeClick}>
      <img alt="logo" src={require('../images/TRC_Logo_reduced.png')} height="40" />
    </Link>
  );

  const startMenuContent = () => {
    if (!isAuthenticated || sessionStorage.getItem('projectSelected') === 'false') {
      return (
        <div id="menu-left">
          <div className="menu-bar-logo">
            {renderLogo()}
            <span>MDI Extraction Tool</span>
          </div>
        </div>
      );
    }

    return (
      <div id="menu-left">
        <div className="main-logo">
          {renderLogo()}
          <span>MDI Extraction Tool</span>
        </div>
        <div className="p-input-icon-right" style={{ width: '25rem' }}>
          <i className="pi pi-search" />
          <InputText placeholder="Search for documents and document types" disabled={isLoading} type="text" style={{ width: '100%' }} onClick={() => setVisible(true)} />
          {renderSearchDialog()}
        </div>
      </div>
    );
  }; 

  return (
    <div className="sticky-menu">
      <Menubar model={menuItems} start={startMenuContent()} className="custom-menubar" />
    </div>
  );
};

export default MenuBar;
