import React, { useRef, useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { useSelectedContext } from '../components/SelectedContext';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { CSSTransition } from 'react-transition-group';
import ImportDocMenu from '../components/ImportDocMenu';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import api from '../api';
import './style/SourceDoc.css';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';

const ProjScreen = (isAdmin) => {

    const { showComponent, setShowComponent, selectedProjectRow, setSelectedProjectRow, skelData, groupLoading, setGroupLoading, setChildFileLoading, setChildFolderLoading, setFCSwitch, fcSwitch } = useSelectedContext();
    const { selectedDocType, selectedDocId } = useParams();
    const [selectedTab, setSelectedTab] = useState('');
    const [sortOrder, setSortOrder] = useState('None');
    const [icon, setIcon] = useState('pi-sort-alpha-down');
    const [sortedPinFake, setSortedPinFake] = useState([]);
    const [sortedAllFake, setSortedAllFake] = useState([]);
    const [projectFolders, setProjectFolders] = useState([]);
    const [projectFoldersPin, setProjectFoldersPin] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [parentId, setParentId] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [folderToRename, setFolder] = useState(0);
    const [folderToRenameName, setFolderName] = useState('');
    const [filter, setFilter] = useState(false);
    const today = new Date().toLocaleDateString();
    const ops = useRef(null);
    const [representatives, setRepresentatives] = useState([]);
    const [filters, setFilters] = useState({
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        date_created: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        representative: { value: null, matchMode: FilterMatchMode.IN }
    });
    const toastBottomLeft = useRef(null);
    const [renameVisible, setRenameVisible] = useState(false);
    const [moveVisible, setMoveVisible] = useState(false);
    const [folders, setFolders] = useState([]);
    const [moveLoading, setMoveLoading] = useState(true);
    const [rootMove, setRootMove] = useState(false);
    const [moveSwitch, setMoveSwitch] = useState(false);
    const [delVisible, setDelVisible] = useState(false);

    const showMessage = (ref, severity, message) => {
        ref.current.show({ severity: severity, summary: message, life: 3000 });
    };

    useEffect(() => {
        resetFilters()
        const storedView = sessionStorage.getItem('selectedView');
        if (storedView !== null) {
            if (storedView === 'Attributes') {
                setSelectedTab('databaseview');
            }
            else if (storedView === 'Source Documents') {
                setSelectedTab('sourcedocs');
            }
        }
    }, [filter, selectedDocId]);

    useEffect(() => {
        if (sortOrder === 'Asc') {
            setIcon('pi pi-sort-alpha-down');
        } else if (sortOrder === 'Desc') {
            setIcon('pi pi-sort-alpha-up-alt');
        } else if (sortOrder === 'None') {
            setIcon('pi pi-sort-alt');
        }
        getIconItems()
        
    }, [projectFolders, projectFoldersPin, sortOrder]);

    const adjustFlexBasis = () => {
        const elements = document.getElementsByClassName('station-folder');
        let maxWidth = 0;
        for (let i = 0; i < elements.length; i++) {
          const width = elements[i].offsetWidth;
          if (width > maxWidth) {
            maxWidth = width;
          }
        }
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.flexBasis = `${maxWidth}px`;
        }
    };

    useEffect(() => {
        if (groupLoading) {
            getRootFolders();
            adjustFlexBasis();
        }
    }, [groupLoading]);

    const resetFilters = () => {
        setFilters({
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date_created: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            representative: { value: null, matchMode: FilterMatchMode.IN }
        });
    };

    const getRootFolders = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const headers = {
            Authorization: `Bearer ${token}`,
          };
      
          const projectId = parseInt(sessionStorage.getItem('selectedProjectId'), 10);
          const docId = parseInt(selectedDocId, 10);
      
          const response = await api.get(`/folders/get-root-folders?project_id=${projectId}&document_type_id=${docId}`, { headers });
          const responseAct = await api.get(`/activity/${projectId}`, { headers });
      
          setRepresentatives(getAllRepresentatives(response.data));
          const formattedProjectFolders = formatFolders(response.data, responseAct.data);
          setProjectFolders(formattedProjectFolders.sort((a, b) => b.date_created - a.date_created));
      
          const responseP = await api.get(`/pinned-folders/get-pinned-folders?project_id=${projectId}&document_type_id=${docId}`, { headers });
          setProjectFoldersPin(formatFolders(responseP.data, responseAct.data).sort((a, b) => b.date_created - a.date_created));
        } catch (error) {
          console.error('Failed to get folders: ', error);
        } finally {
          setGroupLoading(false);
          resetFilters();
      
          if (sessionStorage.getItem('searchName') && sessionStorage.getItem('searchName') !== '') {
            setFilters({
              name: { operator: FilterOperator.AND, constraints: [{ value: sessionStorage.getItem('searchName'), matchMode: FilterMatchMode.STARTS_WITH }] },
              date_created: { operator: FilterOperator.AND, constraints: [{ value: new Date(sessionStorage.getItem('searchDate')), matchMode: FilterMatchMode.DATE_IS }] },
              representative: { value: [sessionStorage.getItem('searchNameRep')], matchMode: FilterMatchMode.IN }
            });
      
            sessionStorage.removeItem('searchName');
            sessionStorage.removeItem('searchDate');
            sessionStorage.removeItem('searchNameRep');
          }
        }
    };  

    const getAllRepresentatives = (data) => {
        const uniqueRepresentatives = Array.from(new Set(data.map(item => item.created_by)));
        return uniqueRepresentatives;
    };

    const formatFolders = (data, activity) => {
        return data.map((item) => {
          const parsedDate = new Date(item.date_created);
      
          if (!isNaN(parsedDate.getTime())) {
            parsedDate.setHours(parsedDate.getHours() - 6);
      
            return {
              name: item.folder_name,
              total_documents: item.total_documents,
              date_created: parsedDate,
              representative: item.created_by,
              folder_id: item.folder_id,
              folder_pinned: item.folder_pinned,
              activityList: activity.filter(activityItem =>
                activityItem.meta_data.folder_id &&
                activityItem.meta_data.folder_id === item.folder_id
              ),
              parent_folder_id: item.parent_folder_id,
              folder_count: item.folder_count
            };
          } else {
            console.error('Invalid date string:', item.date_created);
      
            return {
              name: item.folder_name,
              total_documents: item.total_documents,
              date_created: 'Invalid Date',
              representative: item.created_by,
              folder_id: item.folder_id,
              folder_pinned: item.folder_pinned,
              activityList: activity.filter(activityItem =>
                activityItem.meta_data.folder_id &&
                activityItem.meta_data.folder_id === item.folder_id
              ).sort((a, b) => b.date_created - a.date_created),
              parent_folder_id: item.parent_folder_id,
              folder_count: item.folder_count
            };
          }
        });
    };

    const handlePinClick = (data) => {
        pinFolder(data);
    };

    const handleTitleClick = (data) => { 
        sessionStorage.removeItem("searchDoc");
        sessionStorage.setItem("currentScreen", 'File Source'); 
        sessionStorage.setItem("folderPath", data.name);
        sessionStorage.setItem("folderIdPath", data.folder_id);
        setShowComponent(false);
        setChildFileLoading(true);
        setChildFolderLoading(true);
    };

    const pinFolder = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            await api.post(`/pinned-folders/add-folder-pin/${data.folder_id}`, null, { headers });
            setGroupLoading(true);
            showMessage(toastBottomLeft, 'success', 'Folder Pinned');
        } catch (error) {
            console.error('Failed to get folders: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    }

    const downloadFolder = async (id) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const requestBody = {
                folder_id: id
            };
            const response = await api.post(`/files/download_files`, requestBody, { headers, responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_files.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Failed to download folder: ', error);
        }
    };

    const handleUnpinClick = (data) => {
        unPinFolder(data);
    };

    const unPinFolder = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            await api.delete(`/pinned-folders/remove-folder-pin/${data.folder_id}`, { headers });
            setGroupLoading(true);
            showMessage(toastBottomLeft, 'success', 'Unpinned Folder');
        } catch (error) {
            console.error('Failed to get folders: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    }

    const folderTitle = (rowdata) => {
        return (
            <Link className='folder-title' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.name}/${rowdata.folder_id}`} onClick={() => handleTitleClick(rowdata)}>
                <p className='menu-link'>{rowdata.name}</p>
                {rowdata.folder_pinned && <i className="pi pi-bookmark" style={{ fontSize: '1rem' }}></i>}
            </Link>
        )
    };

    const moveClick = (data) => {
        setFolder(data.folder_id);
        setFolderName(data.name);
        setParentId(data.parent_folder_id);
        setMoveLoading(true);
        getMoveFolders(data.folder_id);
        setMoveVisible(true);
    };

    const ScanMenu = ({ rowdata }) => {
        const op = useRef(null);

        const handleButtonClick = (e) => {
            op.current.toggle(e);
        };
    
        return (
            <div>
                <Button rounded text icon="pi pi-ellipsis-v" onClick={handleButtonClick} />
                <OverlayPanel ref={op}>
                    <div id='doc-opt-menu'>
                        <Link className='doc-opt menu-link' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.name}/${rowdata.folder_id}`} onClick={() => handleTitleClick(rowdata)}>Open folder</Link>
                        <p className='doc-opt menu-link' onClick={() => {setRenameVisible(true);setFolder(rowdata.folder_id);setFolderName(rowdata.name)}}>Rename</p>
                        <p className='doc-opt menu-link' onClick={() => {moveClick(rowdata)}}>Move folder</p>
                        { !rowdata.folder_pinned && <p className='doc-opt menu-link' onClick={() => {handlePinClick(rowdata)}}>Pin folder</p> }
                        { rowdata.folder_pinned && <p className='doc-opt menu-link' onClick={() => {handleUnpinClick(rowdata)}}>Unpin Folder</p> }
                        {(!showComponent || (showComponent && selectedFolder !== rowdata.name)) && <Divider />}
                        {(selectedFolder !== rowdata.name || !showComponent) && <p className='doc-opt menu-link' onClick={() => {setShowComponent(true); setSelectedFolder(rowdata.name); setSelectedProjectRow(rowdata); adjustFlexBasis();}}>Folder info</p>}
                        <Divider />
                        <p className='doc-opt menu-link' onClick={() => downloadFolder(rowdata.folder_id)}>Download</p>
                        {isAdmin.isAdmin && <p className='doc-opt remove-opt' onClick={() => {setDelVisible(true);setFolder(rowdata.folder_id)}}>Delete Folder</p>}
                    </div>
                </OverlayPanel>
            </div>
        );
    };

    const ScanMenuPin = ({ rowdata }) => {
        const op = useRef(null);

        const handleButtonClick = (e) => {
            op.current.toggle(e);
        };
    
        return (
            <div>
                <Button rounded text icon="pi pi-ellipsis-v" onClick={handleButtonClick} />
                <OverlayPanel ref={op}>
                    <div id='doc-opt-menu'>
                        <Link className='doc-opt menu-link' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${rowdata.name}/${rowdata.folder_id}`} onClick={() => handleTitleClick(rowdata)}>Open Folder</Link>
                        <p className='doc-opt menu-link' onClick={() => {setRenameVisible(true);setFolder(rowdata.folder_id);setFolderName(rowdata.name)}}>Rename</p>
                        <p className='doc-opt menu-link' onClick={() => {handleUnpinClick(rowdata)}}>Unpin Folder</p>
                        {(!showComponent || (showComponent && selectedFolder !== rowdata.name)) && <Divider />}
                        {(selectedFolder !== rowdata.name || !showComponent) && <p className='doc-opt menu-link' onClick={() => {setShowComponent(true); setSelectedFolder(rowdata.name); setSelectedProjectRow(rowdata)}}>Folder info</p>}
                        <Divider />
                        <p className='doc-opt menu-link' onClick={() => downloadFolder(rowdata.folder_id)}>Download</p>
                        {isAdmin.isAdmin && <p className='doc-opt remove-opt' onClick={() => {setDelVisible(true);setFolder(rowdata.folder_id)}}>Delete folder</p>}
                    </div>
                </OverlayPanel>
            </div>
        );
    };

    const getIconItems = () => {
        const newSortedPinFake = sortOrder === 'None' ? [...projectFoldersPin] : [...projectFoldersPin].sort((a, b) => {
            if (sortOrder === 'Asc') {
              return a.name.localeCompare(b.name);
            } else if (sortOrder === 'Desc') {
              return b.name.localeCompare(a.name);
            }
        });

        const newSortedAllFake = sortOrder === 'None' ? [...projectFolders] : [...projectFolders].sort((a, b) => {
            if (sortOrder === 'Asc') {
              return a.name.localeCompare(b.name);
            } else if (sortOrder === 'Desc') {
              return b.name.localeCompare(a.name);
            }
        });

        setSortedPinFake(newSortedPinFake);
        setSortedAllFake(newSortedAllFake);
    }

    const closeAllPanels = (index) => {
        if (activeIndex !== index.index) {
            setActiveIndex(index.index);
            setShowComponent(false);
        }
    };

    const handleSortButtonClick = (e) => {
        ops.current.toggle(e);
    }

    const handleAscClick = (e) => {
        setSortOrder('Asc');
        ops.current.toggle(e);
    }

    const handleDescClick = (e) => {
        setSortOrder('Desc');
        ops.current.toggle(e);
    }

    const removeSort = (e) => {
        setSortOrder('None');
        ops.current.toggle(e);
    }

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    }

    const representativeRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={representatives}
                itemTemplate={representativesItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;

        return (
            <div className="flex align-items-center gap-2">
                <span>{representative}</span>
            </div>
        );
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const formik = useFormik({
        initialValues: {
            newName: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.newName === '') {
                errors.newName = 'Please enter a new name';
            }

            return errors;
        },
        onSubmit: () => {
            handleSubmitRename(formik.values);
            formik.resetForm()
        }
    });

    const handleSubmitRename = async (values) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                 Authorization: `Bearer ${token}`,
            };
            const requestBodyAll = {
                "folder_name": values.newName
            };
            const response = await api.put(`/folders/rename-folder/${folderToRename}`, requestBodyAll, { headers });
            const user = await api.get(`/users/user-info/${response.data.user_id}`, { headers });
            const requestBodyAct = {
                'type_id': 5,
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "description": `renamed folder "${folderToRenameName}" to ${values.newName}`,
                "meta_data": {
                    "folder_name": response.data.folder_name,
                    "folder_id": response.data.id,
                    "date_created": response.data.created_at,
                    "created_by": `${user.data.first_name} ${user.data.last_name}`,
                    "parent_folder_id": response.data.parent_folder_id,
                    "doc_type": response.data.document_type_id,
                    "doc_type_name": selectedDocType
                }
            }
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
            setRenameVisible(false)
            setShowComponent(false);
            showMessage(toastBottomLeft, 'success', 'Renamed Folder');
            setGroupLoading(true);
            setFCSwitch(!fcSwitch);
        } catch (error) {
            console.error('Failed to update folder name: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const skelTemplate = () => {
        return (
            <Skeleton width='5rem'/>
        );
    };

    const formik2 = useFormik({
        initialValues: {
            newFolderMoveName: '',
            newFolderMoveId: '',
            checkboxMoveRoot: false
        },
        validate: (data) => {
            let errors = {};

            if (data.newFolderMoveName === '' && !data.checkboxMoveRoot) {
                errors.newFolderMoveName = 'Please select a folder!';
            }

            return errors;
        },
        onSubmit: () => {
            handleSubmitMove(formik2.values);
            formik2.resetForm()
        }
    });

    const handleSubmitMove = async (values) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            let target_id = 0
            if (!values.checkboxMoveRoot) {
                target_id = values.newFolderMoveId
            }
            const apiUrl = `/folders/move-folder/${folderToRename}/${target_id}`;
            const response = await api.put(apiUrl, null, { headers });
            const user = await api.get(`/users/user-info/${parseInt(sessionStorage.getItem('userID'),10)}`, { headers });
            let dest_folder = null
            let description = ''
            if (target_id === 0) {
                description = `moved folder ${folderToRenameName} into the root`
            }   
            else {
                dest_folder = String(values.newFolderMoveName).trim();
                description = `moved folder ${folderToRenameName} into folder: ${values.newFolderMoveName}`
            }
            const requestBodyAct = {
                'type_id': 8,
                "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                "description": description,
                "meta_data": {
                    "dest_folder_name": dest_folder,
                    "moved_folder_id": response.data.id,
                    "moved_folder_name": String(folderToRenameName).trim(),
                    "date_created": response.data.created_at,
                    "created_by": `${user.data.first_name} ${user.data.last_name}`,
                    "new_parent_folder_id": target_id,
                    "doc_type": response.data.document_type_id,
                    "doc_type_name": selectedDocType
                }
            }
            const apiUrlAct = `/activity/add-activity`;
            await api.post(apiUrlAct, requestBodyAct, { headers });
            setMoveVisible(false);
            setGroupLoading(true)
            setMoveSwitch(!moveSwitch)
            setRootMove(false)
            showMessage(toastBottomLeft, 'success', `Folder move successful!`);
            setSelectedFolder(null);
            formik2.resetForm()
        } catch (error) {
            console.error('Failed to move folder: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };

    const isFormFieldInvalid2 = (name) => !!(formik2.touched[name] && formik2.errors[name]);

    const getFormErrorMessage2 = (name) => {
        return isFormFieldInvalid2(name) ? <small className="p-error">{formik2.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const getMoveFolders = async (folder) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const response = await api.get(`/folders/get-move-folders?project_id=${parseInt(sessionStorage.getItem('selectedProjectId'),10)}&document_type_id=${parseInt(selectedDocId,10)}`, { headers });
            setFolders(formatMoveFolders(response.data, folder))
            setMoveLoading(false)
        } catch (error) {
            console.error('Failed to get project folders: ', error);
        }
    };

    const formatMoveFolders = (data, folder) => {
        const filteredData = data
            .filter(({ folder_id }) => folder_id !== folder)
            .map(({ folder_name, folder_id }) => ({ name: folder_name, code: folder_id }));

        return filteredData;
    };

    const moveHeaderElement = (
        <div className='move-header'>
            <p>Move to folder</p>
            {moveLoading && <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.25rem' }}></i>}
        </div>
    );

    const delFooterContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDelVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {setDelVisible(false);handleSubmitDelete()}} autoFocus />
        </div>
    );

    const handleSubmitDelete = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const folderResponse = await api.get(`/folders/get-folder-items/${folderToRename}`, { headers });
            if (folderResponse.data[0].files.length > 0) {
                const requestBody = {
                    "file_ids": folderResponse.data[0].files.map((item) => item.id)
                };
                await api.post(`/files/delete-files`, requestBody, { headers });
                await Promise.all(folderResponse.data[0].files.map(async (item) => {
                    const itemName = item.name.includes("/") ? item.name.substring(item.name.lastIndexOf("/") + 1) : item.name;
                    const requestBodyAct = {
                        'type_id': 4,
                        "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                        "description": `deleted file: ${itemName}`,
                        "meta_data": {
                            "file_id": item.id,
                            "full_file_name": item.name,
                            "project": sessionStorage.getItem('selectedProject'),
                            "client": sessionStorage.getItem('selectedClient')
                        }
                    }
                    const apiUrlAct = `/activity/add-activity`;
                    await api.post(apiUrlAct, requestBodyAct, { headers });
                }));
            }
            await Promise.all(folderResponse.data[0].folders.map(async (item) => {
                await api.delete(`/folders/delete-folder/${item.id}`, { headers })
                const requestBodyAct = {
                    "type_id": 4,
                    "project_id": parseInt(sessionStorage.getItem('selectedProjectId'),10),
                    "description": `deleted folder: ${item.name}`,
                    "meta_data": {
                        "folder_id": item.id
                    }
                }
                const apiUrlAct = `/activity/add-activity`;
                await api.post(apiUrlAct, requestBodyAct, { headers });
            }));
            await api.delete(`batches/clean-up-batches/${parseInt(sessionStorage.getItem('selectedProjectId'),10)}`, { headers })
            showMessage(toastBottomLeft, 'success', 'Folder Deleted');
            setGroupLoading(true);
            setFCSwitch(!fcSwitch);
        } catch (error) {
            console.error('Failed to delete folder: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    };
  
    return (
    <div id='data-tab'>
        <TabView activeIndex={activeIndex} style={{ flexGrow: 1, width: showComponent ? '67%' : 'auto' }} onTabChange={(activeIndex) => {closeAllPanels(activeIndex); resetFilters()}}>
            <TabPanel header="All">
                {sessionStorage.getItem('selectedListStyle') === 'left' &&
                    (groupLoading ? (
                        <DataTable showGridlines value={skelData}>
                            <Column header="Name" field='Test' body={skelTemplate}></Column>
                            <Column header="Total Documents" body={skelTemplate}/>
                            <Column header="Total Folders" body={skelTemplate}/>
                            <Column header="Date Created" body={skelTemplate}/>
                            <Column header="Created By" body={skelTemplate}/>
                            <Column header="Folder Options" body={skelTemplate}/>
                        </DataTable>
                    ) : (
                    <DataTable scrollable className='group-table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} showGridlines value={projectFolders} selectionMode='single' emptyMessage="No items found." filters={filters} selection={selectedProjectRow} removableSort tableStyle={{ minWidth: '' }}>
                        <Column header="Name" filter filterPlaceholder="Search by name" sortable field='name' body={folderTitle} />
                        <Column header="Total Documents" sortable field="total_documents" />
                        <Column header="Total Folders" sortable field="folder_count" />
                        <Column header="Date Created" dataType="date" filterField="date_created" field="date_created" sortable filter filterElement={dateFilterTemplate} body={(rowData) => { return <span>{rowData.date_created.toLocaleDateString('en-US')}</span>; }} />
                        <Column sortable filter header="Created By" field="representative" filterField="representative" showFilterMatchModes={false} style={{ minWidth: '14rem' }} body={representativeBodyTemplate} filterElement={representativeRowFilterTemplate} />
                        <Column header="Folder Options" field="name" body={(rowdata, index) => (<ScanMenu rowdata={rowdata} />)} />
                    </DataTable>
                ))}
                {sessionStorage.getItem('selectedListStyle') === 'right' &&
                    (groupLoading ? (
                        <div className='button-plus-loading'>
                            <Button disabled label="Sort and Filter" iconPos="right" outlined icon={icon} style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSortButtonClick}>
                                {filter && <i className="pi pi-filter-fill" style={{marginLeft:'10px'}}></i>}
                                {!filter && <i className="pi pi-filter" style={{marginLeft:'10px'}}></i>}
                            </Button>
                            <img alt="loading gif" src={require('../images/Infinity-1s-200px.gif')} className="p-mr-2" width='100px' />
                        </div>
                    ) : (
                    <div id='button-plus-icon'>
                        <Button label="Sort and Filter" iconPos="right" outlined icon={icon} style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSortButtonClick}>
                            {filter && <i className="pi pi-filter-fill" style={{marginLeft:'10px'}}></i>}
                            {!filter && <i className="pi pi-filter" style={{marginLeft:'10px'}}></i>}
                        </Button>
                        <OverlayPanel ref={ops}>
                            <div id='doc-opt-menu'>
                                <p style={{fontWeight:'bold', margin:'8px 0px'}}>Filter by:</p>
                                <div className='sort-menu-items'>
                                    <p className='doc-opt-proj menu-link-sort' onClick={(e) => setFilter(true)}>Recently added</p>
                                    <p className='doc-opt-proj remove-opt-sort'onClick={(e) => setFilter(false)}>Remove filter</p>
                                    <Divider className='sort-menu-divider'/>
                                    <p className='doc-opt-proj menu-link-sort' onClick={handleAscClick}>Sort A-Z</p>
                                    <p className='doc-opt-proj menu-link-sort' onClick={handleDescClick}>Sort Z-A</p>
                                    <p className='doc-opt-proj remove-opt-sort' onClick={removeSort}>Remove Sort</p>
                                </div>
                            </div>
                        </OverlayPanel>
                        <div className='folder-list-proj-doc group-root-list'>
                            {!filter && sortedAllFake.map((item, index) => (
                                <div key={index} className='station-folder'>
                                    <div className='folder-and-icon'>
                                        <i className="pi pi-folder"></i>
                                        <Link className='folder-title' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.name}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                            <p className='menu-link'>{item.name}</p>
                                            {item.folder_pinned && <i className="pi pi-bookmark" style={{ fontSize: '1rem' }}></i>}
                                        </Link>
                                    </div>
                                    <ScanMenu rowdata={item} />
                                </div>
                            ))}
                            {filter && sortedAllFake.filter(item => item.date_created.toLocaleDateString() === today)
                                .map((item, index) => (
                                <div key={index} className='station-folder'>
                                    <div className='folder-and-icon'>
                                        <i className="pi pi-folder"></i>
                                        <Link className='folder-title' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.name}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                            <p className='menu-link'>{item.name}</p>
                                            {item.folder_pinned && <i className="pi pi-bookmark" style={{ fontSize: '1rem' }}></i>}
                                        </Link>
                                    </div>
                                    <ScanMenu rowdata={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                    ))
                }
            </TabPanel>
            <TabPanel header="Pinned">
                {sessionStorage.getItem('selectedListStyle') === 'left' &&
                    (groupLoading ? (
                        <DataTable showGridlines value={skelData}>
                            <Column header="Name" field="total_documents" body={skelTemplate} />
                            <Column header="Total Documents" body={skelTemplate}/>
                            <Column header="Total Folders" body={skelTemplate}/>
                            <Column header="Date Created" body={skelTemplate}/>
                            <Column header="Created By" body={skelTemplate}/>
                            <Column header="Folder Options" body={skelTemplate}/>
                        </DataTable>
                    ) : (
                    <DataTable scrollable className='group-table' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} value={projectFoldersPin} filters={filters} selectionMode='single' selection={selectedProjectRow} removableSort tableStyle={{ minWidth: '' }} emptyMessage="No items found.">
                        <Column header="Name" filter filterPlaceholder="Search by name" sortable field='name' body={folderTitle} />
                        <Column header="Total Documents" sortable field="total_documents" />
                        <Column header="Total Folders" sortable field="folder_count" />
                        <Column header="Date Created" dataType="date" filterField="date_created" field="date_created" sortable filter filterElement={dateFilterTemplate} body={(rowData) => <span>{rowData.date_created.toLocaleDateString('en-US')}</span>} />
                        <Column sortable filter header="Created By" field="representative" filterField="representative" showFilterMatchModes={false} style={{ minWidth: '14rem' }} body={representativeBodyTemplate} filterElement={representativeRowFilterTemplate} />
                        <Column header="Folder Options" field="name" body={(rowData, index) => (<ScanMenuPin rowdata={rowData} />)} />
                    </DataTable>
                ))}
                {sessionStorage.getItem('selectedListStyle') === 'right' &&
                    (groupLoading ? (
                        <div className='button-plus-loading'>
                            <Button disabled label="Sort and Filter" iconPos="right" outlined icon={icon} style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSortButtonClick}>
                                {filter && <i className="pi pi-filter-fill" style={{marginLeft:'10px'}}></i>}
                                {!filter && <i className="pi pi-filter" style={{marginLeft:'10px'}}></i>}
                            </Button>
                            <img alt="loading gif" src={require('../images/Infinity-1s-200px.gif')} className="p-mr-2" width='100px' />
                        </div>
                    ) : (
                        <div id='button-plus-icon'>
                            <Button label="Sort and Filter"  iconPos="right" outlined icon={icon} style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSortButtonClick}>
                                {filter && <i className="pi pi-filter-fill" style={{marginLeft:'10px'}}></i>}
                                {!filter && <i className="pi pi-filter" style={{marginLeft:'10px'}}></i>}
                            </Button>
                            <OverlayPanel ref={ops}>
                                <div id='doc-opt-menu'>
                                    <p style={{fontWeight:'bold', margin:'8px 0px'}}>Filter by:</p>
                                    <p className='doc-opt menu-link-sort' onClick={(e) => setFilter(true)}>Recently added</p>
                                    <p className='doc-opt remove-opt-sort'onClick={(e) => setFilter(false)}>Remove filter</p>
                                    <Divider />
                                    <p className='doc-opt menu-link-sort' onClick={handleAscClick}>Sort A-Z</p>
                                    <p className='doc-opt menu-link-sort' onClick={handleDescClick}>Sort Z-A</p>
                                    <p className='doc-opt remove-opt-sort' onClick={removeSort}>Remove Sort</p>
                                </div>
                            </OverlayPanel>
                            <div className='folder-list-proj-doc'>
                                {!filter && sortedPinFake
                                .map((item, index) => (
                                    <div key={index} className='station-folder'>
                                        <div className='folder-and-icon'>
                                            <i className="pi pi-folder"></i>
                                            <Link className='folder-title' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.name}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                                <p className='menu-link'>{item.name}</p>
                                            </Link>
                                        </div>
                                        <ScanMenuPin rowdata={item} />
                                    </div>
                                ))}
                                {filter && sortedPinFake.filter(item => item.date_created.toLocaleDateString() === today)
                                .map((item, index) => (
                                    <div key={index} className='station-folder'>
                                        <div className='folder-and-icon'>
                                            <i className="pi pi-folder"></i>
                                            <Link className='folder-title' to={`/${selectedTab}/${selectedDocType}/${selectedDocId}/${item.name}/${item.folder_id}`} onClick={() => handleTitleClick(item)}>
                                                <p className='menu-link'>{item.name}</p>
                                            </Link>
                                        </div>
                                        <ScanMenuPin rowdata={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </TabPanel>
        </TabView>
        <Toast ref={toastBottomLeft} position="bottom-left" />
        <Dialog header="Enter New Folder Name" draggable visible={renameVisible} style={{ width: '25vw' }} onHide={() => setRenameVisible(false)}>
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-3">
                <div className="flex flex-column gap-2">
                    {getFormErrorMessage('newName')}
                    <InputText id="newName" name="newName" checked={formik.values.newName} onChange={(e) => {formik.setFieldValue('newName', e.target.value)}} />
                </div>
                <Button label="Submit" type="submit"/>
            </form>
        </Dialog>
        <Dialog header={moveHeaderElement} draggable visible={moveVisible} style={{ width: '35vw' }} onHide={() => {setMoveVisible(false);setSelectedFolder(null);formik2.resetForm();setRootMove(false)}}>
            <form onSubmit={formik2.handleSubmit} id='move-form-folder'>
                <Dropdown id="newFolderMoveF" name="newFolderMoveF" disabled={moveLoading || rootMove} checked={formik2.values.newFolderMoveF} value={selectedFolder} onChange={(e) => {setSelectedFolder(e.value);formik2.setFieldValue('newFolderMoveName', e.value.name); formik2.setFieldValue('newFolderMoveId', e.value.code)}} options={folders} optionLabel="name" placeholder="Select Destination Folder" className="w-full" />
                <div className='flex justify-content-between'>
                    {getFormErrorMessage2('newFolderMoveF')}
                </div>
                <Button label="Submit" type="submit"/>
            </form>
        </Dialog>
        <Dialog header="Are you sure?" draggable visible={delVisible} style={{ width: '35vw' }} onHide={() => {setDelVisible(false)}} footer={delFooterContent}>
            <p id='confirmation-text'>Clicking 'Yes' will permanently delete this folder along with all its contents.</p>
        </Dialog>
        <CSSTransition in={showComponent} timeout={300} classNames="slide1" unmountOnExit style={{ width: '30%' }}>
            <div>
                <ImportDocMenu docActivity={true} selectedFolder={selectedFolder} data={selectedProjectRow} />
            </div>
        </CSSTransition>
    </div>
    )
  };
  
  export default ProjScreen;

  