import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ActivityItem from '../components/ActivityItem';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import './style/Activity.css';
import api from '../api';

const tabviewStyle = {
  marginTop: '5px'
};

const Activity = () => {
  const [activityItems, setActivityItems] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [filteredActivityItems, setFilteredActivityItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dates, setDates] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ first: 0, rows: 5 });
  const typeDisplayNames = {
    scan: 'Imports',
    export: 'Exports',
    file_move: 'File Moves',
    folder_move: 'Folder Moves',
    delete: 'Deletes',
    folder_create: 'Created Folders',
    folder_rename: 'Folder Renames',
    manual_edit: 'Manual Edits'
  };

  useEffect(() => {
    const fetchActivityData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const activityResponse = await api.get(`/activity/${parseInt(sessionStorage.getItem('selectedProjectId'), 10)}`, { headers });
        const docTypesResponse = await api.get(`/doc-types/`, { headers });
        
        const sortedActivity = activityResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setActivityItems(sortedActivity);
        setDocTypes(docTypesResponse.data);
      } catch (error) {
        console.error('Failed to get items: ', error);
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchActivityData();
  }, []);

  useEffect(() => {
    const filterData = () => {
      let data = activityItems;

      if (dates && dates[0] && dates[1]) {
        const [startDate, endDate] = dates.map(date => new Date(date).setHours(0, 0, 0, 0));
        data = data.filter(item => {
          const itemDate = new Date(item.date).setHours(0, 0, 0, 0);
          return itemDate >= startDate && itemDate <= endDate;
        });
      }

      if (keyword) {
        const lowerKeyword = keyword.toLowerCase();
        data = data.filter(item => 
          item.description.toLowerCase().includes(lowerKeyword) ||
          item.name.toLowerCase().includes(lowerKeyword)
        );
      }

      setFilteredActivityItems(data);
    };

    filterData();
  }, [activityItems, dates, keyword]);

  const handlePageChange = (e) => {
    setPagination({ first: e.first, rows: e.rows });
  };

  const tabItems = useMemo(() => {
    const types = [
      'scan', 'export', 'file_move', 'folder_move', 'delete', 
      'folder_create', 'folder_rename', 'manual_edit'
    ];

    return types.map(type => ({
      type,
      items: filteredActivityItems.filter(item => item.type === type)
    }));
  }, [filteredActivityItems]);

  const renderTabPanel = useCallback((tabItem) => (
    <TabPanel key={tabItem.type} disabled={isLoading} header={typeDisplayNames[tabItem.type]}>
      {tabItem.items.length === 0 ? (
        <p>No items found.</p>
      ) : (
        <>
          {tabItem.items.slice(pagination.first, pagination.first + pagination.rows).map((item, index) => (
            <ActivityItem
              key={index}
              type={item.type}
              desc={item.description}
              date={item.date}
              allTab={false}
              name={item.name}
              data={item.meta_data}
              docTypes={docTypes}
              present={item.present}
              relevant={item.relevant}
            />
          ))}
          <Paginator
            first={pagination.first}
            rows={pagination.rows}
            totalRecords={tabItem.items.length}
            rowsPerPageOptions={[5, 10, 20, 30]}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </TabPanel>
  ), [filteredActivityItems, docTypes, pagination, isLoading]);

  return (
    <div style={tabviewStyle}>
      <div id='activity-top-bar'>
        <div id='activity-menu' style={{ paddingLeft: '5px' }}>
          <p style={{ color: '#043d75', fontSize: '18px' }}>Filter by Date:</p>
          <Calendar disabled={isLoading} style={{ width: '15vw' }} value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput />
          <p style={{ color: '#043d75', fontSize: '18px' }}>Filter by Keyword:</p>
          <InputText disabled={isLoading} style={{ width: '15vw' }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
          <Button disabled={isLoading} label="Clear Filters" outlined onClick={() => { setFilteredActivityItems(activityItems); setDates(null); setKeyword(''); }} />
        </div>
      </div>
      {isLoading ? (
        <div className="loading-container">
          <img alt="logo" src={require('../images/Infinity-1s-200px.gif')} height="80" />
        </div>
      ) : (
        <TabView id='activity-tab-view' activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); setPagination(prev => ({ ...prev, first: 0 })); }}>
          {tabItems.map(renderTabPanel)}
        </TabView>
      )}
    </div>
  );
};

export default Activity;
