import React, {useState} from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { useParams } from 'react-router-dom';
import api from '../api';
import './style/Login.css';

const PasswordReset = () => {

    const [resetError, setResetError] = useState(null);
    const { selectedUserID } = useParams();
    const [successVisible, setSuccessVisible] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            conpassword: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.email === '') {
                errors.username = 'Please enter a username';
            }

            if (data.password === '') {
                errors.password = 'Please enter a password';
            }

            if (data.conpassword === '') {
                errors.conpassword = 'Please confirm new password';
            }
            else if (data.conpassword !== data.password) {
                errors.conpassword = 'Passwords must match';
            }

            return errors;
        },
        onSubmit: () => {
            handleSubmit(formik.values);
        }
    });

    const handleSubmit = async (values) => {
        try {
            await api.put(`/users/update-password/${values.password}/${values.email}/${selectedUserID}`, null,  null);
        } catch (error) {
            if (error.code === 'ERR_NETWORK') {
                setResetError('Error - Check Network Connection')
            }
            else if (error.response.status === 500) {
                setResetError('Error - Try again');
            }
            else if (error.response.status === 403) {
                setResetError('Error - Incorrect email');
            }
            else if (error.response.status === 405) {
                setResetError('Error - Please use the link from your email');
            }
        } finally {
            setSuccessVisible(true);
            formik.resetForm();
        }
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const goHome = () => {
        window.location.href = '/';
    };

    const footerContent = () => {
        return (
            <div>
                <Button label="Navigate to login screen" onClick={() => goHome()}/>
            </div>
        )
    };

    return (
        <div id='login-form'>
            <div id='login-title'>
                <h1 style={{ fontSize: '40px' }}>MDI - Password Reset</h1>
                <p className='login-text'>Please enter your new password.</p>
            </div>
            {<p className="p-error">{resetError}</p>}
            <form onSubmit={formik.handleSubmit} className="flex flex-column align-items-center gap-3">
                <div className="flex flex-column gap-2">
                    <div className='login-label'>
                        <label htmlFor="email">Email Address</label>
                        {getFormErrorMessage('email')}
                    </div>
                    <InputText id="email" name="emailusername" checked={formik.values.email} onChange={(e) => {formik.setFieldValue('email', e.target.value)}} style={{ width: '40vw' }} aria-describedby="email-help" />
                </div>
                <div className="flex flex-column gap-2">
                    <div className='login-label'>
                        <label htmlFor="password">New Password</label>
                        {getFormErrorMessage('password')}
                    </div>
                    <Password id="password" name="password" feedback={false} checked={formik.values.password} onChange={(e) => {formik.setFieldValue('password', e.target.value)}} aria-describedby="password-help" />
                </div>
                <div className="flex flex-column gap-2">
                    <div className='login-label'>
                        <label htmlFor="conpassword">Confirm Password</label>
                        {getFormErrorMessage('conpassword')}
                    </div>
                    <Password id="conpassword" name="conpassword" feedback={false} checked={formik.values.conpassword} onChange={(e) => {formik.setFieldValue('conpassword', e.target.value)}} aria-describedby="conpassword-help" />
                </div>
                <Button id='sign-in-button' style={{ width: '40vw' }} label="Submit" type="submit"/>
            </form>
            <Dialog header="Your password has been changed" footer={footerContent} visible={successVisible} style={{ width: '40vw' }} onHide={() => setSuccessVisible(false)}>
                <p style={{margin:'0px'}}>Your password has been successfully changed. Navigate back to the login screen and use your new password to login.</p>
            </Dialog>
        </div>
    )
};

export default PasswordReset;