import React from 'react';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import './style/DocumentCard.css';
import { useSelectedContext } from './SelectedContext';

function DocumentCard({ title, total, lastUpload, docType }) {

  const { setGroupLoading } = useSelectedContext();

  const handleClick = () => {
    if (!sessionStorage.getItem('selectedListStyle')) {
      sessionStorage.setItem('selectedListStyle', 'left');
    }
    sessionStorage.setItem('currentScreen', 'Folder Source');
    setGroupLoading(true);
  };

  const modifiedTitle = encodeURIComponent(title.toLowerCase());

  return (
    <Card title={title} className='doc-card'>
        <div className='card-text'>
            <div className='doc-sec'>
                <p className='doc-title'>Total documents:</p> 
                <p className='doc-num'>{total}</p>
            </div>
            <div className='doc-sec'>
                <p className='doc-title'>Documents in last upload:</p>
                <p className='doc-num'>{lastUpload}</p>
            </div>
        </div>
        <Link to={`/sourcedocs/${modifiedTitle}/${docType}`} style={{textDecoration:'none'}} onClick={handleClick}>
          <div className='card-doc-link'>
            <p className='card-doc-link-text'>View more</p>
            <i className="pi pi-angle-right card-doc-link-text" style={{'fontSize': '1.25em'}}></i>
          </div>
        </Link>
    </Card>
  )
}

export default DocumentCard