import React, { useState, useEffect, useRef } from 'react';
import api from '../api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useSelectedContext } from '../components/SelectedContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import './style/Admin.css';

const Admin = () => {

    const [userLoading, setUserLoading] = useState(true);
    const [roleLoading, setRoleLoading] = useState(true);
    const [projectLoading, setProjectLoading] = useState(true);
    const [editRoleVisible, setEditRoleVisible] = useState(false);
    const [editProjectVisible, setEditProjectVisible] = useState(false);
    const [projectEditScreen, setProjectEditScreen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [userProjects, setUserProjects] = useState([]);
    const [otherProjects, setOtherProjects] = useState([]);
    const { skelData } = useSelectedContext();
    const [editDisabled, setEditDisabled] = useState(true);
    const toastBottomLeft = useRef(null);
    const [userChangeSwitch, setUserChangeSwitch] = useState(true);
    const [accessSwitch, setAccessSwitch] = useState(true);

    const tabviewStyle = {
        marginTop: '10px'
    };

    useEffect(() => {
        getUsers()
        getRoles()
    }, [userChangeSwitch]);

    useEffect(() => {
        if (editProjectVisible) {
            getUserProjects()
        }
    }, [editProjectVisible, accessSwitch]);

    const getUsers = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const userResponse = await api.get(`/users/`, { headers });
            setUsers(formatUserResponse(userResponse.data))
        } catch (error) {
            console.error('Failed to get user info: ', error);
        }
        finally {
            setUserLoading(false);
        }
    }

    const formatUserResponse = (data) => {
        return data.map((item, index) => {
            const parsedDate = new Date(item.created_at);
            parsedDate.setHours(parsedDate.getHours() - 6)

                return {
                    name: `${item.first_name} ${item.last_name}`,
                    join_date: parsedDate,
                    email: item.email,
                    current_role: item.role_id,
                    id:item.id
                };
            } 
        );
    };

    const getRoles = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const roleResponse = await api.get(`/security-roles/`, { headers });
            setRoles(roleResponse.data)
        } catch (error) {
            console.error('Failed to get security roles: ', error);
        }
        finally {
            setRoleLoading(false);
        }
    }

    const changeRole = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            await api.put(`/security-roles/promote-user/${selectedUser.id}/${selectedRole.id}`,null, { headers });
            setEditRoleVisible(false);
            resetEditForm();
            showMessage(toastBottomLeft, 'success', 'User Role Changed');
            setUserChangeSwitch(!userChangeSwitch);
        } catch (error) {
            console.error('Failed to update user role: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    }

    const getUserProjects = async () => {
        try {
            setProjectLoading(true);
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const projectResponse = await api.get(`/project-access/get-user-projects/${selectedUser.id}`, { headers });
            setUserProjects(projectResponse.data)
            const otherProjectResponse = await api.get(`/project-access/get-all-other-projects/${selectedUser.id}`, { headers });
            setOtherProjects(otherProjectResponse.data)
        } catch (error) {
            console.error('Failed to get user projects: ', error);
        }
        finally {
            setProjectLoading(false);
        }
    }

    const skelTemplate = () => {
        return (
            <Skeleton width='5rem'/>
        );
    };

    const roleTemplate = (data) => {
        const role_name = roles.find((role) => role.id === data.current_role);
        return (
            <p>{role_name.role_title}</p>
        );
    };

    const actionTemplate = (data) => {
        
        return (
            <div className='admin-action'>
                <Button className='action-button' label='Edit role' onClick={() => {setSelectedUser(data);setEditRoleVisible(true)}}/>
                <Button className='action-button' label='View/Assign projects' severity="warning" onClick={() => {setSelectedUser(data);setEditProjectVisible(true)}}/>
            </div>
        );
    };

    const resetEditForm = () => {
        setSelectedRole('');
        setEditDisabled(true);
    };

    const showMessage = (ref, severity, message) => {
        ref.current.show({ severity: severity, summary: message, life: 3000 });
    };

    const grantAccess = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const requestBody = {
                "user_id": selectedUser.id,
                "project_id": data.project_id
            };
            await api.post(`/project-access/add-permission`, requestBody, { headers });
            setAccessSwitch(!accessSwitch)
            showMessage(toastBottomLeft, 'success', `Access to project "${data.project}" Granted`);
        } catch (error) {
            console.error('Failed to grant access: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    }

    const removeAccess = async (data) => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            await api.delete(`/project-access/remove-permission/${selectedUser.id}/${data.project_id}`, { headers });
            setAccessSwitch(!accessSwitch)
            showMessage(toastBottomLeft, 'success', `Access to project "${data.project}" Removed`);
        } catch (error) {
            console.error('Failed to remove access: ', error);
            showMessage(toastBottomLeft, 'error', 'Error');
        }
    }

    const assignTemplate = (data) => {
        return (
            <p className='access-button' onClick={() => {grantAccess(data)}}>Grant Access</p>
        );
    };

    const removeTemplate = (data) => {
        return (
            <p className='access-button' onClick={() => {removeAccess(data)}} style={{color:'darkred'}}>Remove Access</p>
        );
    };

  return (
    <div>
        <h1>User List</h1>
        <div style={tabviewStyle}>
            {(userLoading || roleLoading ? (
                <DataTable showGridlines value={skelData}>
                    <Column header="Name" body={skelTemplate}/>
                    <Column header="Email" body={skelTemplate}/>
                    <Column header="Joined" body={skelTemplate}/>
                    <Column header="Role" body={skelTemplate}/>
                    <Column header="Actions" body={skelTemplate}/>
                </DataTable>
            ) : (
                <DataTable scrollable className='user-table' showGridlines value={users} selectionMode='single' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No users found." >
                    <Column header="Name" field='name' sortable/>
                    <Column header="Email" field='email' sortable/>
                    <Column header="Joined" field='join_date' body={(rowData) => (<span>{rowData.join_date.toLocaleDateString('en-US')}</span>)}/>
                    <Column header="Role" body={roleTemplate}/>
                    <Column header="Actions" body={actionTemplate}/>
                </DataTable>
            ))}
        </div>
        <Dialog header="Edit User Role" style={{ width: '25vw' }} draggable visible={editRoleVisible} onHide={() => {setEditRoleVisible(false);resetEditForm()}}>
            { selectedUser && (
                <div className='edit-user-form'>
                    <div className='edit-user-info'>
                        <div className='edit-user-half'>
                            <b style={{ fontSize: '18px' }}>Selected User:</b>
                            <p>{selectedUser.name}</p>
                        </div>
                        <div>
                            <b style={{ fontSize: '18px' }}>User Role:</b>
                            <p>{roles.find((role) => role.id === selectedUser.current_role).role_title}</p>
                        </div>
                    </div>
                    <div className='edit-user-info'>
                        <b style={{ fontSize: '18px' }}>Change role:</b>
                        <Dropdown value={selectedRole} options={roles.filter(role => role.role_title !== roles.find((role) => role.id === selectedUser.current_role).role_title)} 
                            optionLabel="role_title" style={{width: '50%'}} onChange={(e) => {setSelectedRole(e.target.value);setEditDisabled(false)}} placeholder="Select a new role"
                        />
                    </div>
                    <Button label='Submit' disabled={editDisabled} style={{width: '100%', marginTop:'5px'}} onClick={() => {changeRole()}}/>
                </div>
            )}
        </Dialog>
        <Dialog header="Edit/Assign Projects" style={{ width: '35vw' }} draggable visible={editProjectVisible} onHide={() => {setEditProjectVisible(false)}}>
            { selectedUser && !projectEditScreen && (
                <div className='edit-project-form'>
                    <div className='edit-user-info'>
                        <div className='edit-user-half'>
                            <b style={{ fontSize: '18px' }}>Selected User:</b>
                            <p>{selectedUser.name}</p>
                        </div>
                        <div>
                            <b style={{ fontSize: '18px' }}>User Role:</b>
                            <p>{roles.find((role) => role.id === selectedUser.current_role).role_title}</p>
                        </div>
                    </div>
                    <p>Assigned projects:</p>
                    {projectLoading && (
                        <DataTable showGridlines value={skelData} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                            <Column header="Client" body={skelTemplate}/>
                            <Column header="Project" body={skelTemplate}/>
                            <Column header="Remove" body={skelTemplate}/>
                        </DataTable>
                    )}
                    {!projectLoading && (
                        <DataTable scrollable className='assigned-table' showGridlines value={userProjects} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                            <Column header="Client" field='client'/>
                            <Column header="Project" field='project'/>
                            <Column header="Remove" body={removeTemplate}/>
                        </DataTable>
                    )}
                    <p>To assign this user to other projects, click <a id="att-click" onClick={() => setProjectEditScreen(true)}>here</a></p>
                </div>
            )}
            { selectedUser && projectEditScreen && (
                <div>
                    <div className='edit-user-info'>
                        <div className='edit-user-half'>
                            <b style={{ fontSize: '18px' }}>Selected User:</b>
                            <p>{selectedUser.name}</p>
                        </div>
                        <div>
                            <b style={{ fontSize: '18px' }}>User Role:</b>
                            <p>{roles.find((role) => role.id === selectedUser.current_role).role_title}</p>
                        </div>
                    </div>
                    <p>Remaining projects:</p>
                    {projectLoading && (
                        <DataTable showGridlines value={skelData} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                            <Column header="Client" body={skelTemplate}/>
                            <Column header="Project" body={skelTemplate}/>
                            <Column header="Assign" body={skelTemplate}/>
                        </DataTable>
                    )}
                    {!projectLoading && (
                        <DataTable scrollable className='assigned-table' showGridlines value={otherProjects} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                            <Column header="Client" field='client'/>
                            <Column header="Project" field='project'/>
                            <Column header="Assign" body={assignTemplate}/>
                        </DataTable>
                    )}
                    <p>To go back, click <a id="att-click" onClick={() => setProjectEditScreen(false)}>here</a></p>
                </div>
            )}
        </Dialog>
        <Toast ref={toastBottomLeft} position="bottom-left" />
    </div>
  )
};

export default Admin;